//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import elCrf from './index.vue'; // import AFTableColumn from 'af-table-column'

export default {
  components: {
    'el-dynamic': elCrf // AFTableColumn

  },
  props: {
    option: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      }
    },
    value: {
      type: String,
      required: false,
      default: function _default() {
        return '';
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function handler(v) {
        if (v === JSON.stringify(this.formData) || !v) return;
        this.formData = JSON.parse(v);
      }
    },
    option: {
      immediate: true,
      handler: function handler(v) {
        var dataSource = [];
        v.dataSources.forEach(function (item) {
          Object.keys(item).forEach(function (k) {
            item[k].forEach(function (data, i) {
              if (!dataSource[i]) {
                dataSource[i] = {};
              }

              dataSource[i][k] = data;
            });
          });
        });
        this.dataSource = dataSource;
        this.columns = v.columns;
      }
    },
    formData: {
      deep: true,
      handler: function handler(v) {
        this.$emit('input', JSON.stringify(v));
      }
    }
  },
  data: function data() {
    return {
      dataSource: [],
      columns: [],
      formData: {}
    };
  },
  methods: {
    calculateMinWidth: function calculateMinWidth(label) {
      // 设置一个基础宽度，可以根据需要调整
      var baseWidth = 100; // 根据列名的字数计算最小宽度

      return Math.max(label.length * 16 + 40, baseWidth);
    }
  }
};