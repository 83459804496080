var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.sectionLayout.itemLayout === "horizontal"
      ? _c("div", { staticClass: "hor-cover" }, [
          _c("div", { staticClass: "crf-title" }, [
            _c("span", [_vm._v(_vm._s(_vm.option.title))])
          ]),
          _c("div")
        ])
      : _c("div", { staticClass: "crf-title" }, [
          _vm._v(_vm._s(_vm.option.title))
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }