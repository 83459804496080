export default (function (_ref) {
  var _ref$namespace = _ref.namespace,
      namespace = _ref$namespace === void 0 ? '$loadingPlugin' : _ref$namespace;
  return {
    data: function data() {
      return {
        loadingPlugin__: {}
      };
    },
    beforeCreate: function beforeCreate() {
      var methods = this.$options.methods;

      if (methods) {
        this.$options.methods = createLoading(methods);
      }
    },
    watch: {
      loadingPlugin__: {
        handler: function handler(val) {
          this[namespace] = val;
          this.$forceUpdate();
        },
        immediate: true,
        deep: true
      }
    }
  };
});
export function createLoading(obj) {
  var newObj = {};

  var _loop = function _loop(k) {
    if (Object.hasOwnProperty.call(obj, k)) {
      newObj[k] = function () {
        var _this = this;

        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        var rtn = obj[k].apply(this, args); // 监听异步函数

        if (rtn instanceof Promise) {
          return new Promise(function (resolve, reject) {
            _this.$set(_this.loadingPlugin__, k, true);

            rtn.then(resolve).catch(reject).finally(function () {
              _this.$set(_this.loadingPlugin__, k, false);
            });
          });
        } else {
          return rtn;
        }
      };
    }
  };

  for (var k in obj) {
    _loop(k);
  }

  return newObj;
}