//
//
//
//
//
//
import QRCode from 'qrcode';
export default {
  name: 'QRCode',
  props: {
    content: {
      type: String,
      default: '测试二维码'
    },
    width: {
      type: String,
      default: '30'
    },
    height: {
      type: String,
      default: '30'
    },
    codeName: {
      type: String,
      default: '二维码'
    },
    load: {
      type: Boolean,
      default: true
    },
    view: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      canvasId: ''
    };
  },
  computed: {},
  created: function created() {
    var _this = this;

    this.canvasId = this.getUUID();
    this.$nextTick(function () {
      _this.init();
    });
  },
  mounted: function mounted() {},
  methods: {
    init: function init() {
      var width = this.width;
      var height = this.height;
      QRCode.toCanvas(document.getElementById(this.canvasId), this.content, {
        width: width,
        height: height,
        toSJISFunc: QRCode.toSJIS
      }, function () {});
    },
    getUUID: function getUUID() {
      var d = new Date().getTime();
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == 'x' ? r : r & 0x7 | 0x8).toString(16);
      });
      return uuid;
    } // 下载二维码
    // loadCode() {
    //   let [F, S, a] = [
    //     navigator.userAgent.indexOf("Firefox") > -1,
    //     document.getElementById(this.canvasId).toDataURL("image/png"),
    //     document.createElement("a")
    //   ];
    //   // var dataurl = showel.toDataURL();
    //   var arr = S.split(","),
    //     mime = arr[0].match(/:(.*?);/)[1],
    //     bstr = atob(arr[1]),
    //     n = bstr.length,
    //     u8arr = new Uint8Array(n);
    //   while (n--) {
    //     u8arr[n] = bstr.charCodeAt(n);
    //   }
    //   var file = new File([u8arr], this.codeName + ".png", { type: mime });
    //   $A.FU(file, data => {
    //     // alert(1)
    //     // window.location.href = data;
    //     [a.href, a.download] = [data, this.codeName];
    //     // a.download = '二维码';
    //     if (F) {
    //       let evt = document.createEvent("MouseEvents");
    //       evt.initEvent("click", true, true);
    //       a.dispatchEvent(evt);
    //     } else {
    //       a.click();
    //     }
    //   });
    // },
    // insertContentLoad(content, size) {
    //   const ele = document.createElement("canvas");
    //   ele.style.width = size.width || "100" + "px";
    //   ele.style.height = size.height || "100" + "px";
    //   QRCode.toCanvas(
    //     ele,
    //     content,
    //     {
    //       width: size.width || "100",
    //       height: size.height || "100",
    //       toSJISFunc: QRCode.toSJIS
    //     },
    //     error => {}
    //   );
    //   let [F, S, a] = [
    //     navigator.userAgent.indexOf("Firefox") > -1,
    //     ele.toDataURL("image/png"),
    //     document.createElement("a")
    //   ];
    //   [a.href, a.download] = [S, size.name];
    //   // a.download = '二维码';
    //   if (F) {
    //     let evt = document.createEvent("MouseEvents");
    //     evt.initEvent("click", true, true);
    //     a.dispatchEvent(evt);
    //   } else {
    //     a.click();
    //   }
    // }

  },
  watch: {
    content: function content(val) {
      this.init();
    }
  }
};