/* eslint-disable */

/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  '工具': {
    width: 32.031,
    height: 32,
    viewBox: '0 0 1025 1024',
    data: '<defs/><path pid="0" d="M981.76 81.024c-56.384-56.896-147.712-56.896-204.032 0l-360.704 364.16 204.032 206.08 360.704-364.16c56.384-56.96 56.384-149.184 0-206.08zm-442.304 368.64l-24.32-24.512L820.16 117.12c6.656-6.72 17.536-6.72 24.384 0 6.656 6.72 6.656 17.728 0 24.512L539.456 449.664zm50.816 51.2l-24.384-24.512L896.32 142.848c6.656-6.784 17.536-6.784 24.32-.064 6.656 6.784 6.656 17.728-.128 24.512l-330.24 333.568zm50.752 51.264l-24.384-24.512L921.6 219.648c6.656-6.784 17.536-6.72 24.256 0s6.656 17.728 0 24.512L641.024 552.128zM351.296 816.64L332.8 797.952 502.784 626.24l-66.816-67.52L265.92 730.432l-18.176-18.368-40 21.056L74.048 951.744 113.856 992l214.72-133.76zM227.904 32c-19.584 0-38.4 2.752-56.512 7.424 1.792 1.6 3.904 2.816 5.568 4.608l82.176 82.944c41.856 42.24 41.856 110.848 0 153.088-41.856 42.24-109.76 42.24-151.616 0l-82.176-82.88c-4.288-4.352-7.872-9.152-11.328-14.016C5.12 207.872 0 234.304 0 262.08c0 127.04 102.016 230.016 227.904 230.016 125.824 0 227.84-103.04 227.84-230.08S353.664 32 227.904 32zm696.32 910.016c49.408-49.984 49.408-131.008 0-180.992L742.208 577.408 562.944 758.272l182.144 183.744c49.472 49.92 129.664 49.92 179.136 0zM802.752 819.328c20.48-20.672 53.632-20.672 74.112 0s20.48 54.208 0 74.944c-20.48 20.608-53.632 20.608-74.112 0-20.48-20.8-20.48-54.272 0-74.944z"/>'
  }
});