var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    _vm._b(
      {
        attrs: {
          visible: _vm.visible,
          "before-close": _vm.handleBeforeClose,
          "append-to-body": "",
          "custom-class": "my-dialog",
          "close-on-click-modal": false,
          loading: _vm.loading
        }
      },
      "el-dialog",
      Object.assign({}, _vm.$attrs, _vm.option),
      false
    ),
    [
      !_vm.noheader
        ? _c(
            "span",
            { staticClass: "title", attrs: { slot: "title" }, slot: "title" },
            [_vm._t("title")],
            2
          )
        : _vm._e(),
      _vm._t("default", [_c("span", [_vm._v("这是一段信息")])]),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _vm._t("footer", [
            _c(
              "el-button",
              {
                on: {
                  click: function($event) {
                    return _vm.$emit("handleClose")
                  }
                }
              },
              [_vm._v("取 消")]
            ),
            _vm.okText
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("handleOk")
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.okText))]
                )
              : _vm._e()
          ])
        ],
        2
      ),
      _vm.isOcx
        ? _c("iframe", {
            staticStyle: {
              position: "fixed",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              "z-index": "-99"
            },
            attrs: {
              src: "about:blank",
              frameBorder: "0",
              marginHeight: "0",
              marginWidth: "0"
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }