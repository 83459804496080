var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-submenu",
        {
          staticClass: "menu-item-wrap",
          attrs: {
            index: _vm.model.code ? _vm.model.code : "",
            route: _vm.model
          }
        },
        [
          _c("template", { slot: "title" }, [
            _c("i", {
              staticClass: "iconfont",
              class:
                _vm.iconMapping[
                  _vm.currentRoute.meta && _vm.currentRoute.meta.icon
                ]
            }),
            _c("span", [_vm._v(_vm._s(_vm.model.meta.title))])
          ]),
          _vm.model.children
            ? _vm._l(_vm.model.children, function(child) {
                return _c("el-menu-" + child.type, {
                  key: child.id,
                  tag: "component",
                  attrs: { model: child }
                })
              })
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }