var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Dialog",
        {
          class: [
            { "identify-dialog": true },
            { "report-dialog": _vm.componentsType === "report" },
            { "custom-dialog": _vm.componentsType === "customize" }
          ],
          attrs: {
            visible: _vm.showIdentifyDialog,
            handleBeforeClose: function() {
              return (_vm.showIdentifyDialog = false)
            }
          }
        },
        [
          _c("span", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v("智能识别")
          ]),
          _vm.componentsType === "customize"
            ? _c("customizePage", { on: { "close-dialog": _vm.handleClose } })
            : _c(
                "div",
                [
                  _c(
                    "el-upload",
                    {
                      staticStyle: { width: "300px" },
                      attrs: {
                        action: "/",
                        accept: "image/*",
                        "http-request": _vm.enhanceReportfileUpload,
                        "show-file-list": false
                      }
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small", type: "primary" } },
                        [_vm._v("点击上传")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function() {
                      return (_vm.showIdentifyDialog = false)
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }