function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
export default {
  props: {
    value: {
      type: [String, Number, Date],
      required: false
    },
    option: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      }
    }
  },
  computed: _objectSpread({}, mapState('study', ['typeOperate'])),
  data: function data() {
    return {
      optionReadonly: false,
      valueFormat: 'yyyy-MM-dd'
    };
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function handler(v) {
        if (!v) return;

        if (v.match(/(\d+)年/) && v.match(/(\d+)月/) && v.match(/(\d+)日/)) {
          // 将年份提取出来
          var year = v.match(/(\d+)年/)[1]; // 将中文月份转换为数字

          var month = v.match(/(\d+)月/)[1]; // 将中文日期转换为数字

          var day = v.match(/(\d+)日/)[1]; // 使用字符串插值创建新的日期格式

          var formattedDate = "".concat(year, "-").concat(month.padStart(2, '0'), "-").concat(day.padStart(2, '0'));
          v = formattedDate;
          this.$emit('input', v);
        }
      }
    },
    option: {
      immediate: true,
      deep: true,
      handler: function handler(option) {
        if (option.readOnly) {
          if (option.readOnly === 1) {
            this.optionReadonly = true;
          }
        } else {
          this.optionReadonly = false;
        }

        if (option.format) {
          if (option.format === 'year') {
            this.valueFormat = 'yyyy';
          } else if (option.format === 'month') {
            this.valueFormat = 'yyyy-MM';
          } else {
            this.valueFormat = 'yyyy-MM-dd';
          }
        }

        var year = new Date().getFullYear();
        var month = new Date().getMonth() + 1;
        if (month < 10) month = '0' + month;
        var dates = new Date().getDate(); // 默认值

        if (!this.value && option.defaultValue) {
          if (option.defaultValue === 'now') {
            this.$emit('input', "".concat(year, "-").concat(month, "-").concat(dates));
          } else {
            this.$emit('input', option.defaultValue);
          }
        }
      }
    }
  },
  methods: {
    onChangeHandler: function onChangeHandler(v) {
      this.$emit('input', v);
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.$emit('input', undefined);
  }
};