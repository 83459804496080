var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cover" },
    [
      _vm.option.showStyle != "tag"
        ? _c(
            "el-radio-group",
            {
              staticClass: "radioGroup",
              attrs: { value: _vm.value || _vm.defaultValue },
              on: { input: _vm.onChangeHandler }
            },
            _vm._l(_vm.list, function(item) {
              return _c(
                "el-radio",
                {
                  key: item.value,
                  staticClass: "radioItem",
                  staticStyle: { "margin-right": "0px" },
                  attrs: {
                    label: item.value,
                    disabled: _vm.optionReadonly || _vm.typeOperate
                  },
                  nativeOn: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.clickItem(item.value)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(item.label) + " ")]
              )
            }),
            1
          )
        : _vm._e(),
      _vm.option.showStyle != "tag"
        ? _c(
            "div",
            { staticStyle: { "margin-left": "24px" } },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
      _vm.option.showStyle === "tag"
        ? _c(
            "el-radio-group",
            {
              staticClass: "radio-button",
              attrs: { value: _vm.value || _vm.defaultValue }
            },
            _vm._l(_vm.list, function(item) {
              return _c(
                "el-radio-button",
                {
                  key: item.value,
                  staticClass: "radio-button-Item",
                  staticStyle: { "margin-right": "0px" },
                  attrs: {
                    label: item.value,
                    disabled: _vm.optionReadonly || _vm.typeOperate
                  },
                  nativeOn: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.clickItem(item.value)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(item.label) + " ")]
              )
            }),
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }