export default {
  home: 'icon-shouye-shouye',
  test: 'icon--huanzheguanli',
  follow: 'icon-zhichiyiyuanyaodian',
  mark: 'icon-qianyuejilu',
  case: 'icon-a-tixingdengpao',
  role: 'icon-yonghujiaosepeizhi',
  people: 'icon-suoyourenyuan',
  calendar: 'icon-rili',
  data: 'icon-shuju2',
  wechat: 'icon-weixin',
  manage: 'icon-fabuguanli',
  subject: 'icon-icon_xiangmu',
  project: 'icon-a-xiangmuguanli3',
  doing: 'icon-shiyan',
  list: 'icon-xinxiliebiao',
  crf: 'icon-shujuguanli',
  setting: 'icon-shezhixitongshezhigongnengshezhishuxing',
  organize: 'icon-jigou',
  job: 'icon-jiaoseguanli',
  menu: 'icon-caidan',
  person: 'icon-yonghutianchong',
  random: 'icon-suijimoren'
};