//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    title: {
      default: ''
    },
    noheader: {
      default: false
    },
    nofooter: {
      default: false
    }
  }
};