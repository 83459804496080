function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Pagination from './Pagination';
export default {
  name: 'myTable',
  components: {
    Pagination: Pagination
  },
  props: {
    columns: {
      type: Array,
      default: function _default() {
        return [{
          label: '标题',
          dataIndex: '数据的键',
          render: 'boolean | 是否自定义渲染逻辑'
        }];
      }
    },
    dataSource: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    page: {
      type: Object,
      default: function _default() {
        return null;
      } // default: () => ({
      //   total: 19,
      //   page: 1,
      //   pageSize: 10
      // })

    },
    nopage: {
      type: Boolean,
      default: false
    },
    selection: {
      type: Boolean,
      default: false
    },
    index: {
      type: Boolean,
      default: false
    },
    indexWidth: {
      type: String,
      default: '100'
    },
    expand: {
      type: Boolean,
      default: false
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper'
    },
    pagerCount: {
      type: Number,
      default: 5
    },
    align: {
      type: String,
      default: 'left'
    },
    selectData: Array
  },
  data: function data() {
    return {
      // defaultColumusProps: {
      //   align: 'center'
      // },
      fakePageSize: 10,
      fakePage: 1
    };
  },
  computed: {
    getData: function getData() {
      var data;

      if (this.page) {
        data = this.dataSource;
      } else if (!this.nopage) {
        data = this.dataSource.slice((this.fakePage - 1) * this.fakePageSize, this.fakePage * this.fakePageSize);
      } else {
        data = this.dataSource;
      }

      return data;
    },
    defaultColumusProps: function defaultColumusProps() {
      return {
        align: this.align
      };
    },
    getFakePage: function getFakePage() {
      return {
        page: this.fakePage,
        total: this.dataSource.length,
        pageSize: this.fakePageSize
      };
    }
  },
  watch: {
    dataSource: function dataSource() {
      if (this.page) {
        if (this.dataSource.length === 0 && this.page.page > 1) {
          this.pageChange(_objectSpread(_objectSpread({}, this.page), {}, {
            page: 1
          }));
        }
      } else {
        if (this.dataSource.slice((this.fakePage - 1) * this.fakePageSize, this.fakePage * this.fakePageSize).length === 0 && this.fakePage > 1) {
          this.fackPageChange({
            page: 1,
            pageSize: this.fakePageSize
          });
        }
      }
    }
  },
  methods: {
    // pageChange(page) {
    //   console.log(page, 'index')
    // },
    fackPageChange: function fackPageChange(_ref) {
      var page = _ref.page,
          pageSize = _ref.pageSize;
      this.fakePageSize = pageSize;
      this.fakePage = page;
      this.$emit('handlePageChange', page, pageSize);
    },
    selectRow: function selectRow(selection, row) {
      this.$emit('selectRowData', selection, row);
    },
    indexMethod: function indexMethod(index) {
      if (this.page) {
        return index + 1 + (this.page.page - 1) * this.page.pageSize;
      } else {
        return index + 1 + (this.fakePage - 1) * this.fakePageSize;
      }
    },
    getValFromStrKey: function getValFromStrKey(obj, key) {
      if (~key.indexOf('.')) {
        return key.split('.').reduce(function (p, c) {
          return p ? p[c] : p;
        }, obj);
      }

      return obj[key];
    }
  }
};