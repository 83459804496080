var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dy-form", staticStyle: { position: "relative" } },
    [
      _c(
        "el-table",
        { attrs: { data: _vm.dataSource } },
        _vm._l(_vm.columns, function(item) {
          return _c("el-table-column", {
            key: item.name,
            attrs: {
              prop: item.name,
              label: item.label,
              align: "center",
              "min-width": _vm.calculateMinWidth(item.label)
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("el-dynamic", {
                        attrs: {
                          option: scope.row[scope.column.property],
                          formData: _vm.formData
                        },
                        model: {
                          value:
                            _vm.formData[scope.row[scope.column.property].name],
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formData,
                              scope.row[scope.column.property].name,
                              $$v
                            )
                          },
                          expression:
                            "formData[scope.row[scope.column.property].name]"
                        }
                      })
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }