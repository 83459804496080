var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cover checkBox-cover" },
    [
      _vm.option.showStyle != "tag"
        ? _c(
            "el-checkbox-group",
            {
              staticClass: "checkBoxItem",
              attrs: { value: _vm.value || [] },
              on: { input: _vm.onChangeHandler }
            },
            [
              _vm._l(_vm.list, function(item) {
                return _c(
                  "el-checkbox",
                  {
                    key: item.value + item.label,
                    ref: "checkItem",
                    refInFor: true,
                    staticClass: "checkItem",
                    attrs: {
                      border: "",
                      value: item.value,
                      label: item.value,
                      disabled: _vm.optionReadonly || _vm.typeOperate
                    }
                  },
                  [_vm._v(" " + _vm._s(item.label) + " ")]
                )
              }),
              _vm.isAddition
                ? _c(
                    "el-form-item",
                    { attrs: { label: _vm.addition } },
                    [_c("el-input")],
                    1
                  )
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm.option.showStyle != "tag"
        ? _c(
            "div",
            { staticStyle: { "margin-left": "24px" } },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
      _vm.option.showStyle === "tag"
        ? _c(
            "el-checkbox-group",
            {
              staticClass: "checkBox-button",
              attrs: { value: _vm.value || [] },
              on: { input: _vm.onChangeHandler }
            },
            [
              _vm._l(_vm.list, function(item) {
                return _c(
                  "el-checkbox-button",
                  {
                    key: item.value + item.label,
                    staticClass: "check-button-item",
                    attrs: {
                      border: "",
                      value: item.value,
                      label: item.value,
                      disabled: _vm.optionReadonly || _vm.typeOperate
                    }
                  },
                  [_vm._v(" " + _vm._s(item.label) + " ")]
                )
              }),
              _vm.isAddition
                ? _c(
                    "el-form-item",
                    { attrs: { label: _vm.addition } },
                    [_c("el-input")],
                    1
                  )
                : _vm._e()
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }