import { render, staticRenderFns } from "./select.vue?vue&type=template&id=12ac3630&scoped=true&"
import script from "./select.vue?vue&type=script&lang=js&"
export * from "./select.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12ac3630",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/gaoxiaoxu/workCodeSpace/医疗数据库/fozo-medical-research-cloud-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('12ac3630')) {
      api.createRecord('12ac3630', component.options)
    } else {
      api.reload('12ac3630', component.options)
    }
    module.hot.accept("./select.vue?vue&type=template&id=12ac3630&scoped=true&", function () {
      api.rerender('12ac3630', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/dynamic-form/components/select.vue"
export default component.exports