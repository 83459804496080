function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

export function getFileName(url) {
  var execed = /\/([^/]+$)/.exec(url);
  if (!execed) return '';
  return execed[1];
}
export function replaceUrl(url) {
  var reUrl;
  var urlObject = new URL(url);

  if (window.location.host !== urlObject.origin) {
    reUrl = 'http://' + window.location.host + urlObject.pathname;
  }

  return reUrl;
}
var TYPE_NUMBER = ['int', 'float'];
var REG_PHONE = new RegExp(/^1[3456789]\d{9}$/);

function validateIdCard(val) {
  var p = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
  var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
  var parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
  var code = val.substring(17);

  if (p.test(val)) {
    var sum = 0;

    for (var i = 0; i < 17; i++) {
      sum += val[i] * factor[i];
    }

    if (parity[sum % 11] == code.toUpperCase()) {
      return true;
    }
  }

  return false;
} // function validatePhone(val) {
//   var p = /^1[3456789]\d{9}$/
//   if (p.test(val)) {
//     return true
//   }
//   return false
// }


export function parseValidateRules(type, option) {
  if (!type || !option) return [];
  var rules = [];

  if (option.required === 1) {
    rules.push({
      required: true,
      message: "\u8BF7".concat(option.optionsValue ? '选择选项' : '输入数据'),
      trigger: ['blur', 'change']
    });
  }

  if (!option.validate) return rules;

  if (TYPE_NUMBER.includes(option.validate.type)) {
    // int float型校验
    rules.push({
      validator: function validator(rule, value, callback) {
        if (value) {
          if (option.validate.type === 'int') {
            if (!/^[-+]?\d+$/.test(value)) {
              callback(new Error(option.validate.errorMsg));
              return;
            }
          } else if (option.validate.type === 'float') {
            if (!/^[-+]?\d*(\.\d+)?$/.test(value)) {
              callback(new Error(option.validate.errorMsg));
              return;
            }

            if (option.validate.precision) {
              var regex = new RegExp("^[-+]?\\d+(\\.\\d{".concat(0, ",", option.validate.precision, "})?$"));

              if (!regex.test(value)) {
                callback(new Error(option.validate.errorMsg));
                return;
              }
            }
          }

          if (option.validate.min && +value < +option.validate.min) {
            callback(new Error(option.validate.errorMsg));
            return;
          }

          if (option.validate.max && +value > +option.validate.max) {
            callback(new Error(option.validate.errorMsg));
            return;
          }

          callback();
        } else {
          callback();
        }
      },
      message: option.validate.errorMsg,
      trigger: ['blur', 'change']
    });
  } else if (option.validate.type === 'email') {
    rules.push({
      type: 'email',
      message: option.validate.errorMsg,
      trigger: ['blur', 'change'],
      required: false
    });
  } else if (option.validate.type === 'phone') {
    rules.push({
      pattern: REG_PHONE,
      message: option.validate.errorMsg,
      trigger: ['blur', 'change']
    });
  } else if (option.validate.type === 'idcard') {
    rules.push({
      validator: function validator(rule, value, callback) {
        if (value) {
          if (validateIdCard(value)) {
            callback();
          } else {
            callback(new Error(option.validate.errorMsg));
          }
        } else {
          callback();
        }
      },
      trigger: ['blur', 'change']
    });
  }

  if ((option.validate.min || option.validate.max) && !TYPE_NUMBER.includes(option.validate.type)) {
    var rule = {
      message: option.validate.errorMsg,
      trigger: ['blur', 'change']
    };

    if (option.validate.min) {
      rule['min'] = +option.validate.min;
    }

    if (option.validate.max) {
      rule['max'] = +option.validate.max;
    }

    rules.push(rule);
  }

  if (option.validate.regexp) {
    rules.push({
      pattern: new RegExp(option.validate.regexp),
      message: option.validate.errorMsg
    });
  }

  return rules;
}
/**
 *
 * type 区分是什么类型的format
 * rule [] 第一项表位置，第二项表format内容
 */

export function formatInput(type, rule, value) {
  rule = JSON.parse(rule);

  if (type === 'decimal') {
    // 在后两位位置插入小数点
    if (value.indexOf('.') != -1) {
      // return ((value * 1000) * 0.01).toFixed(2)
      var site = value.length - 1 - value.indexOf('.');
      return (value * Math.pow(10, site) * 0.01).toFixed(2);
    } else {
      return value * 0.01;
    }
  } else if (type === 'interval') {
    // 每间隔多少位，插入什么符号
    if (rule[0] === 1 && rule[1] === '-') {
      var reg = /(\d)(?=(?:\d{1})+$)/g;
      var newNumber = value.toString().replace(reg, '$1-');
      return newNumber;
    }
  } else if (type === 'precision') {
    // 保留小数点后几位，以什么方式保留
    var _rule = rule,
        _rule2 = _slicedToArray(_rule, 2),
        decimalPlaces = _rule2[0],
        roundingRule = _rule2[1];

    var factor = Math.pow(10, decimalPlaces);
    var result;

    switch (roundingRule) {
      case 'R':
        // 四舍五入
        result = Math.round(value * factor) / factor;
        break;

      case 'U':
        // 向上取整
        result = Math.ceil(value * factor) / factor;
        break;

      case 'D':
        // 向下取整
        result = Math.floor(value * factor) / factor;
        break;

      default:
        throw new Error('Invalid rounding rule: must be "R", "U", or "D"');
    } // 判断是否为整数，如果是整数则补上 .0


    if (Number.isInteger(result) && decimalPlaces > 0) {
      return result.toFixed(decimalPlaces);
    }

    return result.toString();
  }
}