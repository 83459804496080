var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "fade-transform", mode: "out-in" } },
    [
      _c(
        "div",
        { staticClass: "container-fluid" },
        [
          _c(
            "keep-alive",
            { attrs: { include: "Map,Vedio" } },
            [_c("router-view", { key: _vm.key })],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }