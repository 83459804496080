import { TokenKey, UserKey, RefreshToken } from '@/constants'; // import store from '@/store'

export function getToken() {
  var auth;

  try {
    auth = localStorage.getItem(TokenKey) || '';
  } catch (error) {
    auth = '';
  }

  return auth;
}
export function setToken() {
  var token = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  localStorage.setItem(TokenKey, token);
} // 检查用户是否拥有该权限

export function checkPerm(perms) {
  // const userPerms = store.getters['user/userPerms']
  // if (process.env.NODE_ENV === 'development') {
  //   return true
  // }
  // if (typeof perms === 'string') {
  //   return !!~userPerms.indexOf(perms)
  // }
  return true;
} // 持久化用户信息

export function setUser(user) {
  localStorage.setItem(UserKey, JSON.stringify(user));
}
export function getUser() {
  var user;

  try {
    user = JSON.parse(localStorage.getItem(UserKey));
  } catch (error) {
    user = {};
  }

  return user;
}
export function getRefresh() {
  var auth;

  try {
    auth = localStorage.getItem(RefreshToken) || '';
  } catch (error) {
    auth = '';
  }

  return auth;
}
export function setRefresh() {
  var token = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  localStorage.setItem(RefreshToken, token);
}