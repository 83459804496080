var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "QRCode", style: { width: _vm.width, height: _vm.height } },
    [
      _c("canvas", {
        style: { width: _vm.width, height: _vm.height },
        attrs: { id: _vm.canvasId }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }