// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/popup_title_bg2@3x.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/img/icon_close@3x.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/img/circle-close1.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".my-dialog {\n  border-radius: 15px;\n}\n.my-dialog .el-dialog__header {\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n}\n.my-dialog .el-dialog__header span {\n      color: #fff;\n}\n.my-dialog .el-dialog__header .el-dialog__headerbtn .el-dialog__close {\n      background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat center/cover;\n}\n.my-dialog /deep/ .el-dialog__header {\n    position: relative;\n    padding: 15px 20px;\n}\n.my-dialog /deep/ .el-dialog__headerbtn {\n    top: 13px !important;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") left no-repeat;\n    background-size: cover;\n}\n.my-dialog .title {\n    font-size: 18px;\n    color: #000;\n    font-weight: bold;\n}\n", ""]);
// Exports
exports.locals = {
	"primaryCol": "#099dad",
	"menuBg": "transparent",
	"menuTCol": "#fff",
	"subMenuBg": "#172a5a",
	"navBarMB": "0px",
	"navBarHeaderH": "72px",
	"navBarBreakH": "32px"
};
module.exports = exports;
