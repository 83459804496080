var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      attrs: {
        value: _vm.value || _vm.defaultValue,
        clearable: "",
        placeholder: _vm.option.placeholder,
        disabled: _vm.optionReadonly || _vm.typeOperate,
        filterable: ""
      },
      on: { input: _vm.onChangeHandler, blur: _vm.onInputChange }
    },
    _vm._l(_vm.list, function(item) {
      return _c("el-option", {
        key: item.value,
        attrs: { label: item.label, value: item.value }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }