//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { scrollTo } from '@/utils/scroll-to';
export default {
  name: 'RfPagination',
  props: {
    background: {
      type: Boolean,
      default: true
    },
    page: {
      type: Object,
      default: function _default() {
        return {
          total: 19,
          page: 1,
          pageSize: 10
        };
      }
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper'
    },
    pageSizes: {
      type: Array,
      default: function _default() {
        return [10, 20, 50, 100];
      }
    },
    autoScroll: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    handleSizeChange: function handleSizeChange(val) {
      this.$emit('pageChange', {
        page: this.page.page,
        pageSize: val
      });
      if (this.autoScroll) scrollTo(0, 800);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.$emit('pageChange', {
        page: val,
        pageSize: this.page.pageSize
      });
      if (this.autoScroll) scrollTo(0, 800);
    }
  }
};