var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dialog",
    {
      attrs: {
        visible: _vm.visible,
        option: _vm.option,
        width: _vm.dialogWidth,
        handleBeforeClose: _vm.handleClose
      },
      on: { handleClose: _vm.handleClose }
    },
    [
      _c(
        "span",
        {
          staticClass: "dialog-title",
          attrs: { slot: "title" },
          slot: "title"
        },
        [
          _c("span", [
            _vm._v(
              _vm._s(_vm.param.showStyle === "allPage" ? "CRF概览" : "打印预览")
            )
          ]),
          _c("i", {
            staticClass: "el-icon-full-screen",
            on: { click: _vm.changeWidth }
          })
        ]
      ),
      [
        _c("div", { staticClass: "crf-view" }, [
          _c(
            "span",
            { attrs: { id: "print" } },
            [
              _c("div", { staticClass: "crf-view_switch" }, [
                _c("div", [
                  _c("span", [_vm._v("患者姓名：" + _vm._s(_vm.subjectName))]),
                  _c("span", [
                    _vm._v("患者编码：" + _vm._s(_vm.identificationCode))
                  ])
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.param.showStyle === "allPage",
                        expression: "param.showStyle === 'allPage'"
                      }
                    ]
                  },
                  [
                    _c(
                      "span",
                      [
                        _c("el-switch", {
                          attrs: {
                            "active-text": "水平排列",
                            "inactive-text": "垂直排列",
                            "active-value": "horizontal",
                            "inactive-value": "vertical"
                          },
                          model: {
                            value: _vm.tableDerection,
                            callback: function($$v) {
                              _vm.tableDerection = $$v
                            },
                            expression: "tableDerection"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "span",
                      [
                        _vm._v("显示空值： "),
                        _c("el-switch", {
                          attrs: { "active-color": "#21acbc" },
                          model: {
                            value: _vm.showEmpty,
                            callback: function($$v) {
                              _vm.showEmpty = $$v
                            },
                            expression: "showEmpty"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ]),
              _vm.crfViewData.length === 0
                ? _c(
                    "div",
                    [_c("el-skeleton", { attrs: { rows: 6, animated: "" } })],
                    1
                  )
                : _vm._l(_vm.crfViewData, function(crf, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "crf-view_table" },
                      [
                        _c("div", { staticClass: "crf-view_table-title" }, [
                          _vm._v(_vm._s(crf.title))
                        ]),
                        _vm._l(crf.children, function(section, index) {
                          return _c(
                            "el-descriptions",
                            {
                              key: index,
                              staticClass: "dialog-table",
                              attrs: {
                                border: true,
                                title: section.title,
                                column: crf.option.cols ? crf.option.cols : 1,
                                direction: _vm.tableDerection
                              }
                            },
                            [
                              _vm._l(section.children, function(item, idx) {
                                return [
                                  item.type != "title" &&
                                  (item.value || _vm.showEmpty)
                                    ? _c(
                                        "el-descriptions-item",
                                        {
                                          key: idx,
                                          attrs: {
                                            label: item.title,
                                            span: item.option.cols
                                              ? item.option.cols
                                              : 1,
                                            "label-class-name":
                                              _vm.tableDerection === "vertical"
                                                ? "vertical-label"
                                                : "",
                                            "content-class-name":
                                              "descriptions-item-content",
                                            "label-style":
                                              _vm.tableDerection === "vertical"
                                                ? ""
                                                : "width: calc(((" +
                                                  item.option.cols +
                                                  " / " +
                                                  crf.option.cols +
                                                  ") / 2) * 100%) ",
                                            "content-style":
                                              _vm.tableDerection === "vertical"
                                                ? ""
                                                : "width: calc(((" +
                                                  item.option.cols +
                                                  " / " +
                                                  crf.option.cols +
                                                  ") / 2) * 100%) "
                                          }
                                        },
                                        [
                                          _c("div", [
                                            item.children &&
                                            item.type != "group"
                                              ? _c(
                                                  "span",
                                                  [
                                                    _c("div", [
                                                      _vm._v(_vm._s(item.value))
                                                    ]),
                                                    _c("componentItem", {
                                                      attrs: {
                                                        childData:
                                                          item.children,
                                                        tableDerection:
                                                          _vm.tableDerection
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _c("span", [
                                                  item.type == "img"
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            display: "flex",
                                                            margin: "5px 0"
                                                          }
                                                        },
                                                        [
                                                          item.option
                                                            .defaultValue
                                                            ? _c(
                                                                "span",
                                                                [
                                                                  _c(
                                                                    "el-image",
                                                                    {
                                                                      staticStyle: {
                                                                        width:
                                                                          "50px",
                                                                        height:
                                                                          "50px",
                                                                        "margin-right":
                                                                          "10px"
                                                                      },
                                                                      attrs: {
                                                                        src:
                                                                          item
                                                                            .option
                                                                            .defaultValue,
                                                                        "preview-src-list": [
                                                                          item
                                                                            .option
                                                                            .defaultValue
                                                                        ]
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : item.value
                                                            ? _c(
                                                                "span",
                                                                _vm._l(
                                                                  _vm.resetImg(
                                                                    item
                                                                  ),
                                                                  function(
                                                                    img,
                                                                    i
                                                                  ) {
                                                                    return _c(
                                                                      "el-image",
                                                                      {
                                                                        key: i,
                                                                        staticStyle: {
                                                                          width:
                                                                            "50px",
                                                                          height:
                                                                            "50px",
                                                                          "margin-right":
                                                                            "10px"
                                                                        },
                                                                        attrs: {
                                                                          src: _vm.resetImg(
                                                                            item
                                                                          )[i],
                                                                          "preview-src-list": _vm.resetImg(
                                                                            item
                                                                          )
                                                                        }
                                                                      }
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      )
                                                    : item.type == "group" &&
                                                      item.option.showStyle ===
                                                        "list"
                                                    ? _c(
                                                        "span",
                                                        [
                                                          item.children
                                                            ? _vm._l(
                                                                item.children,
                                                                function(
                                                                  group,
                                                                  i
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      key: i,
                                                                      class:
                                                                        item
                                                                          .children
                                                                          .length >
                                                                        1
                                                                          ? "grou-child"
                                                                          : ""
                                                                    },
                                                                    [
                                                                      group.length !=
                                                                        0 &&
                                                                      Array.isArray(
                                                                        group
                                                                      )
                                                                        ? [
                                                                            _c(
                                                                              "componentItem",
                                                                              {
                                                                                attrs: {
                                                                                  childData: group,
                                                                                  tableDerection:
                                                                                    _vm.tableDerection
                                                                                }
                                                                              }
                                                                            )
                                                                          ]
                                                                        : _vm._e()
                                                                    ],
                                                                    2
                                                                  )
                                                                }
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        2
                                                      )
                                                    : item.type == "group" &&
                                                      item.option.showStyle ===
                                                        "table"
                                                    ? _c(
                                                        "span",
                                                        [
                                                          item.children
                                                            ? _vm._l(
                                                                item.children,
                                                                function(
                                                                  tableItem,
                                                                  tr
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    { key: tr },
                                                                    [
                                                                      _c(
                                                                        "componentItem",
                                                                        {
                                                                          attrs: {
                                                                            childData: tableItem,
                                                                            groupTable: true,
                                                                            tableDerection:
                                                                              _vm.tableDerection
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                }
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        2
                                                      )
                                                    : _c("span", [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(item.value)
                                                          )
                                                        ]),
                                                        item.option.units
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "unit"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.option
                                                                      .units
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ])
                                                ])
                                          ])
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              })
                            ],
                            2
                          )
                        })
                      ],
                      2
                    )
                  })
            ],
            2
          ),
          _c("div", { staticClass: "crf-view_anchor" }, [
            _c(
              "ul",
              _vm._l(_vm.menuArr, function(crf, index) {
                return _c(
                  "li",
                  {
                    key: crf.id,
                    class:
                      _vm.anchorIdx === index ? "crf-view_anchor_current" : "",
                    attrs: { title: crf.title },
                    on: {
                      click: function($event) {
                        return _vm.chooseCrf(crf, index)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(crf.title) + " ")]
                )
              }),
              0
            )
          ])
        ])
      ],
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            { staticStyle: { width: "100px" }, on: { click: _vm.handleClose } },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "print",
                  rawName: "v-print",
                  value: _vm.printOption,
                  expression: "printOption"
                },
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.param.showStyle === "limitPage",
                  expression: "param.showStyle === 'limitPage'"
                }
              ],
              staticStyle: { width: "100px" },
              attrs: { type: "primary" }
            },
            [_vm._v("打印")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }