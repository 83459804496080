var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._b(
      { staticClass: "page" },
      "div",
      Object.assign({}, _vm.$attrs),
      false
    ),
    [
      _c("div", { staticClass: "page_title" }, [_vm._t("title")], 2),
      _c(
        "div",
        { staticClass: "page_body" },
        [
          _vm._t("search", [
            !_vm.noSearch
              ? _c(
                  "div",
                  { staticClass: "search" },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          _vm._b({}, "el-col", _vm.searchLAttr, false),
                          [
                            _c(
                              "div",
                              { staticClass: "btns" },
                              [_vm._t("search-left")],
                              2
                            )
                          ]
                        ),
                        _c(
                          "el-col",
                          _vm._b({}, "el-col", _vm.searchRAttr, false),
                          [
                            _c(
                              "div",
                              { staticClass: "search-bar__right" },
                              [_vm._t("search-right")],
                              2
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ]),
          _vm._t("default")
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }