function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState, mapMutations } from 'vuex';
export default {
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },
    option: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      }
    },
    showStartDoubtIcon: {
      type: Boolean,
      default: false
    },
    showReplyDoubtIcon: {
      type: Boolean,
      default: false
    },
    groupInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      inputValue: '',
      pickerOptions: null,
      dateType: 'date',
      dateFormat: 'yyyy-MM-dd',
      optionReadonly: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState('study', ['typeOperate'])), {}, {
    pickerValue: {
      get: function get() {
        // 在组件中定义正则映射
        var formatRegexMap = {
          'yyyy': /^\d{4}$/,
          'yyyy-MM': /^\d{4}-\d{2}$/,
          'yyyy-MM-dd': /^\d{4}-\d{2}-\d{2}$/,
          'yyyy-MM-dd HH:mm': /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/,
          'yyyy-MM-dd HH:mm:ss': /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/
        };
        if (!this.inputValue) return null; // 根据当前格式选择正则

        var regex = formatRegexMap[this.dateFormat];

        if (!regex.test(this.inputValue)) {
          return null; // 格式不合法时返回 null
        } // 分割日期和时间部分


        var _this$inputValue$spli = this.inputValue.split(' '),
            _this$inputValue$spli2 = _slicedToArray(_this$inputValue$spli, 2),
            datePart = _this$inputValue$spli2[0],
            timePart = _this$inputValue$spli2[1];

        var _datePart$split = datePart.split('-'),
            _datePart$split2 = _slicedToArray(_datePart$split, 3),
            year = _datePart$split2[0],
            month = _datePart$split2[1],
            day = _datePart$split2[2]; // 处理 "UKUK" 和 "UK" 占位符


        var formattedYear = year === 'UKUK' ? String(new Date().getFullYear()) : year;
        var formattedMonth = month === 'UK' ? String(new Date().getMonth() + 1).padStart(2, '0') : month;
        var formattedDay = day === 'UK' ? String(new Date().getDate()).padStart(2, '0') : day; // 返回格式化后的日期

        var formatValue = formattedYear; // 构建基础日期部分

        if (this.dateFormat.includes('MM')) {
          formatValue += "-".concat(formattedMonth);
        }

        if (this.dateFormat.includes('dd')) {
          formatValue += "-".concat(formattedDay);
        } // 处理时间部分


        if (this.dateFormat.includes('HH:mm')) {
          var _split = (timePart || '').split(':'),
              _split2 = _slicedToArray(_split, 3),
              _split2$ = _split2[0],
              hour = _split2$ === void 0 ? '00' : _split2$,
              _split2$2 = _split2[1],
              minute = _split2$2 === void 0 ? '00' : _split2$2,
              _split2$3 = _split2[2],
              second = _split2$3 === void 0 ? '00' : _split2$3;

          formatValue += " ".concat(hour.padStart(2, '0'), ":").concat(minute.padStart(2, '0'));

          if (this.dateFormat.includes('ss')) {
            formatValue += ":".concat(second.padStart(2, '0'));
          }
        }

        return formatValue;
      },
      set: function set(newValue) {
        this.inputValue = newValue;
      }
    },
    showReplyIcon: function showReplyIcon() {
      var _this = this;

      var groupType = null;

      if (this.groupInfo.groupName) {
        if (this.detail.crfDoubts.length != 0) {
          groupType = this.detail.crfDoubts.some(function (item) {
            return item.itemName === _this.groupInfo.name && item.groupIndex === _this.groupInfo.valueIndex;
          });
        }
      } else {
        groupType = true;
      }

      return this.showReplyDoubtIcon && groupType;
    }
  }),
  watch: {
    value: {
      immediate: true,
      handler: function handler(newValue) {
        this.inputValue = newValue;
      }
    },
    inputValue: function inputValue(newValue) {
      if (newValue) {
        if (newValue.length === 10) {
          this.$emit('input', String(newValue));
        }
      } else {
        this.$emit('input', '');
      }
    },
    option: {
      immediate: true,
      deep: true,
      handler: function handler(option) {
        // 处理只读属性
        if ('readOnly' in option) {
          if (option.readOnly === 1) {
            this.optionReadonly = true;
          } else {
            this.optionReadonly = false;
          }
        }

        if (option.showStyle === 'pastTime') {
          this.pickerOptions = {
            disabledDate: function disabledDate(time) {
              return time.getTime() > Date.now();
            }
          };
        } else if (option.showStyle === 'futureTime') {
          this.pickerOptions = {
            disabledDate: function disabledDate(time) {
              return time.getTime() < Date.now();
            }
          };
        } else {
          return false;
        }

        if ('format' in option && option.format) {
          if (option.format.includes('HH')) {
            this.dateType = 'datetime';
          } else {
            this.dateType = 'date';
          }

          this.dateFormat = option.format;
        }

        var params = _objectSpread(_objectSpread(_objectSpread({
          type: 'add'
        }, this.groupInfo), option.validationEx && {
          validationEx: option.validationEx
        }), option.validationErrorMsg && {
          validationErrorMsg: option.validationErrorMsg
        });

        this.changeSaveItemSuc(params);
      }
    }
  },
  methods: _objectSpread(_objectSpread({}, mapMutations('crf', ['changeSaveItemSuc'])), {}, {
    showDatePicker: function showDatePicker() {
      this.$refs.datePicker.focus(); // 调用 el-date-picker 的 focus 方法
    },
    handleDateChange: function handleDateChange(newDate) {
      // 当选择日期后，更新输入框的值并隐藏日期选择器
      this.inputValue = newDate;
    },
    validateDateInput: function validateDateInput(event) {
      var value = event.target.value;
      var regex = /^(UKUK|\d{4})-(UK|\d{2})-(UK|\d{2})$/;

      if (regex.test(value)) {
        var _value$split = value.split('-'),
            _value$split2 = _slicedToArray(_value$split, 3),
            year = _value$split2[0],
            month = _value$split2[1],
            day = _value$split2[2]; // 校验年份


        if (year !== 'UKUK' && (Number(year) < 1900 || Number(year) > 2100)) {
          this.inputValue = this.lastValidDate;
          return;
        } // 校验月份


        if (month !== 'UK' && (Number(month) < 1 || Number(month) > 12)) {
          this.inputValue = this.lastValidDate;
          return;
        } // 校验日期


        if (day !== 'UK' && (Number(day) < 1 || Number(day) > 31)) {
          this.inputValue = this.lastValidDate;
          return;
        } // 如果校验通过，更新 inputValue 和 lastValidDate


        this.inputValue = value;
        this.lastValidDate = value;
      } else {
        // 如果不符合正则表达式，恢复上一次的有效值
        this.inputValue = this.lastValidDate;
      }
    },
    showDoubtDialog: function showDoubtDialog() {
      this.$emit('OpenDoubtDialog');
    },
    beforeDestroy: function beforeDestroy() {
      this.$emit('input', null);
      this.changeSaveItemSuc(_objectSpread({
        type: 'remove'
      }, this.groupInfo));
    }
  })
};