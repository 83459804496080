var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "child-table" } },
    [
      _vm.showChild
        ? _c(
            "el-descriptions",
            {
              staticClass: "component-table",
              attrs: {
                border: true,
                direction: _vm.tableDerection,
                column: _vm.groupTable ? _vm.childData.length : 1
              }
            },
            [
              _vm._l(_vm.childData, function(item, index) {
                return [
                  _c(
                    "el-descriptions-item",
                    {
                      key: index,
                      attrs: {
                        label: item.title,
                        span:
                          !_vm.groupTable && item.option.cols
                            ? item.option.cols
                            : 1,
                        "label-class-name": "component-item-label"
                      }
                    },
                    [
                      item.children && item.type != "group"
                        ? _c(
                            "span",
                            [
                              _c("div", [_vm._v(_vm._s(item.value))]),
                              _c("componentItem", {
                                attrs: {
                                  childData: item.children,
                                  tableDerection: _vm.tableDerection
                                }
                              })
                            ],
                            1
                          )
                        : _c("span", [
                            item.type == "img"
                              ? _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      margin: "5px 0"
                                    }
                                  },
                                  [
                                    item.option.defaultValue
                                      ? _c(
                                          "span",
                                          [
                                            _c("el-image", {
                                              staticStyle: {
                                                width: "50px",
                                                height: "50px",
                                                "margin-right": "10px"
                                              },
                                              attrs: {
                                                src: item.option.defaultValue,
                                                "preview-src-list": [
                                                  item.option.defaultValue
                                                ]
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : item.value
                                      ? _c(
                                          "span",
                                          _vm._l(_vm.resetImg(item), function(
                                            img,
                                            i
                                          ) {
                                            return _c("el-image", {
                                              key: i,
                                              staticStyle: {
                                                width: "50px",
                                                height: "50px",
                                                "margin-right": "10px"
                                              },
                                              attrs: {
                                                src: _vm.resetImg(item)[i],
                                                "preview-src-list": _vm.resetImg(
                                                  item
                                                )
                                              }
                                            })
                                          }),
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                )
                              : item.type == "group" &&
                                item.option.showStyle === "list"
                              ? _c(
                                  "span",
                                  [
                                    item.children
                                      ? _vm._l(item.children, function(
                                          group,
                                          i
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              key: i,
                                              class:
                                                item.children.length > 1
                                                  ? "grou-child"
                                                  : ""
                                            },
                                            [
                                              group.length != 0
                                                ? [
                                                    _c("componentItem", {
                                                      attrs: {
                                                        childData: group,
                                                        tableDerection:
                                                          _vm.tableDerection
                                                      }
                                                    })
                                                  ]
                                                : _vm._e()
                                            ],
                                            2
                                          )
                                        })
                                      : _vm._e()
                                  ],
                                  2
                                )
                              : _c("span", [
                                  _c("span", [_vm._v(_vm._s(item.value))]),
                                  item.option.units
                                    ? _c("span", { staticClass: "unit" }, [
                                        _vm._v(_vm._s(item.option.units))
                                      ])
                                    : _vm._e()
                                ])
                          ])
                    ]
                  )
                ]
              })
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }