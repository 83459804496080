export default {
  data: function data() {
    return {
      speechCache: [],
      speechMaxLength: 10
    };
  },
  methods: {
    pushSpeech: function pushSpeech(u) {
      var _this = this;

      if (this.speechCache.length >= this.speechMaxLength) return;

      u.onend = function (e) {
        return _this.speechEnd(e);
      };

      if (this.speechCache.length === 0) {
        console.log(u);
        speechSynthesis.speak(u);
      }

      this.speechCache.push(u);
    },
    speechEnd: function speechEnd(event) {
      var idx = this.speechCache.indexOf(event.target);
      console.log(idx, 90999);

      if (~idx) {
        this.speechCache.splice(idx, 1);

        if (this.speechCache.length > 0) {
          speechSynthesis.speak(this.speechCache[0]);
        }
      }
    }
  }
};