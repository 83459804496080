var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-date-picker", {
    staticClass: "crf-datetime",
    attrs: {
      type: "datetime",
      value: _vm.value,
      "value-format": "yyyy-MM-dd HH:mm:ss",
      placeholder: _vm.option.description,
      disabled: _vm.optionReadonly || _vm.typeOperate
    },
    on: { input: _vm.onChangeHandler }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }