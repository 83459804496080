export function isIE11() {
  var u = window.navigator.userAgent.toLocaleLowerCase();
  var ie11 = /(trident)\/([\d.]+)/;
  var style = u.match(ie11);

  if (style) {
    return true;
  } else {
    return false;
  }
}