var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-date-picker", {
    staticClass: "crf-date",
    attrs: {
      type: _vm.option.format ? _vm.option.format : "date",
      value: _vm.value,
      "value-format": _vm.valueFormat,
      placeholder: _vm.option.description,
      disabled: _vm.optionReadonly || _vm.typeOperate
    },
    on: { input: _vm.onChangeHandler }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }