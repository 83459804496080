import perm from '@/directive/permission';
import { checkPerm } from '@/utils/auth';

var install = function install(Vue) {
  Vue.directive('perm', perm);

  Vue.prototype.$hasPerm = function (perm) {
    return checkPerm(perm);
  };
};

if (window.Vue) {
  window.perm = perm;
  window.Vue.use(install);
}

perm.install = install;
export default perm;