/* eslint-disable */

/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  'crm': {
    width: 32,
    height: 32,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M515.83 436.24c95.84 0 173.55-78 173.55-174.25v-54.37c0-79.32-64-143.62-143-143.62h-61c-79 0-143 64.3-143 143.62V262c-.1 96.23 77.62 174.24 173.45 174.24zm379.94 253.47H128.23a130.21 130.21 0 00-5.06 35.74v10.4a192.74 192.74 0 001.19 21.45h775.29a195.19 195.19 0 001.18-21.45v-10.4a130.21 130.21 0 00-5.06-35.74zM512 503.49c-145.1 0-271.56 47.67-338 118.23h676c-66.44-70.56-192.9-118.23-338-118.23zM351.7 960h320.6c92.66 0 172.81-55.06 211.58-135.19H140.12C178.89 904.94 259.05 960 351.7 960z"/>'
  }
});