var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cover" },
    [
      _vm.option.showStyle != "tag"
        ? _c(
            "el-radio-group",
            {
              staticClass: "radioGroup",
              attrs: { value: _vm.value || _vm.defaultValue },
              on: { input: _vm.onChangeHandler }
            },
            _vm._l(_vm.list, function(item) {
              return _c(
                "el-radio",
                {
                  key: item.value,
                  staticClass: "radioItem",
                  staticStyle: { "margin-right": "0px" },
                  attrs: {
                    label: item.value,
                    disabled: _vm.optionReadonly || _vm.typeOperate
                  },
                  nativeOn: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.clickItem(item.value)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(item.label) + " ")]
              )
            }),
            1
          )
        : _vm._e(),
      _vm.option.showStyle === "tag"
        ? _c(
            "el-radio-group",
            {
              staticClass: "radio-button",
              attrs: { value: _vm.value || _vm.defaultValue }
            },
            _vm._l(_vm.list, function(item) {
              return _c(
                "el-radio-button",
                {
                  key: item.value,
                  staticClass: "radio-button-Item",
                  staticStyle: { "margin-right": "0px" },
                  attrs: {
                    label: item.value,
                    disabled: _vm.optionReadonly || _vm.typeOperate
                  },
                  nativeOn: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.clickItem(item.value)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(item.label) + " ")]
              )
            }),
            1
          )
        : _vm._e(),
      _vm.option.showStyle === "open"
        ? _c(
            "el-button",
            { staticClass: "SQGH-btn", on: { click: _vm.open3DSlicer } },
            [_vm._v("打开术前规划软件")]
          )
        : _vm._e(),
      _vm.showStartDoubtIcon && !_vm.showReplyIcon
        ? _c("img", {
            staticStyle: { "margin-left": "5px", cursor: "pointer" },
            attrs: {
              src: require("@/assets/img/start_question.png"),
              width: "15",
              height: "15"
            },
            on: { click: _vm.showDoubtDialog }
          })
        : _vm._e(),
      _vm.showReplyIcon
        ? _c("img", {
            staticStyle: { "margin-left": "5px", cursor: "pointer" },
            attrs: {
              src: require("@/assets/img/reply_question.png"),
              width: "15",
              height: "15"
            },
            on: {
              click: function($event) {
                return _vm.showDoubtDialog()
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }