/* eslint-disable */

/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  'datasync': {
    width: 32,
    height: 32,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M896 85.333H128A42.667 42.667 0 0085.333 128v597.333A42.667 42.667 0 00128 768h768a42.667 42.667 0 0042.667-42.667V128A42.667 42.667 0 00896 85.333zm-42.667 85.334v512H170.667v-512h682.666z" _fill="#2c2c2c" data-spm-anchor-id="a313x.7781069.0.i3" class="selected"/><path pid="1" d="M469.333 725.333h85.334v128h-85.334zM341.33299999999997 853.333h341.334q42.666 0 42.666 42.667t-42.666 42.667H341.333q-42.666 0-42.666-42.667t42.666-42.667zM384 426.667a128 128 0 00211.37 97.109 42.667 42.667 0 1155.638 64.725 213.205 213.205 0 01-321.707-51.669l-3.242-5.547-2.091 3.414a42.667 42.667 0 01-54.443 15.829l-4.352-2.39a42.667 42.667 0 01-15.914-54.442l2.432-4.352 53.504-85.333c21.845-34.859 74.368-21.376 78.506 17.664l.299 4.992zm128-213.334c77.525 0 145.365 41.344 182.741 103.168l3.542 6.144 2.688-4.224a42.667 42.667 0 0154.57-15.53l4.352 2.432a42.667 42.667 0 0115.488 54.57l-2.389 4.352-54.315 85.334C695.723 485.547 640 469.333 640 426.667a128 128 0 00-212.992-95.702 42.667 42.667 0 01-56.747-63.786A212.65 212.65 0 01512 213.333z" _fill="#2c2c2c"/>'
  }
});