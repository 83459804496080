function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState, mapMutations } from 'vuex';
export default {
  props: {
    value: {
      type: [String, Number, Date],
      required: false
    },
    option: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      }
    },
    showStartDoubtIcon: {
      type: Boolean,
      default: false
    },
    showReplyDoubtIcon: {
      type: Boolean,
      default: false
    },
    groupInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState('study', ['typeOperate'])), mapState('crf', ['detail'])), {}, {
    showReplyIcon: function showReplyIcon() {
      var _this = this;

      var groupType = null;

      if (this.groupInfo.groupName) {
        if (this.detail.crfDoubts.length != 0) {
          groupType = this.detail.crfDoubts.some(function (item) {
            return item.itemName === _this.groupInfo.name && item.groupIndex === _this.groupInfo.valueIndex;
          });
        }
      } else {
        groupType = true;
      }

      return this.showReplyDoubtIcon && groupType;
    }
  }),
  data: function data() {
    return {
      optionReadonly: false,
      valueFormat: 'yyyy-MM-dd',
      pickerOptions: null
    };
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function handler(v) {
        if (!v) return;

        if (v.match(/(\d+)年/) && v.match(/(\d+)月/) && v.match(/(\d+)日/)) {
          // 将年份提取出来
          var year = v.match(/(\d+)年/)[1]; // 将中文月份转换为数字

          var month = v.match(/(\d+)月/)[1]; // 将中文日期转换为数字

          var day = v.match(/(\d+)日/)[1]; // 使用字符串插值创建新的日期格式

          var formattedDate = "".concat(year, "-").concat(month.padStart(2, '0'), "-").concat(day.padStart(2, '0'));
          v = formattedDate;
          this.$emit('input', v);
        }
      }
    },
    option: {
      immediate: true,
      deep: true,
      handler: function handler(option) {
        if (option.readOnly) {
          if (option.readOnly === 1) {
            this.optionReadonly = true;
          }
        } else {
          this.optionReadonly = false;
        }

        if (option.format) {
          if (option.format === 'year') {
            this.valueFormat = 'yyyy';
          } else if (option.format === 'month') {
            this.valueFormat = 'yyyy-MM';
          } else {
            this.valueFormat = 'yyyy-MM-dd';
          }
        }

        var year = new Date().getFullYear();
        var month = new Date().getMonth() + 1;
        if (month < 10) month = '0' + month;
        var dates = new Date().getDate(); // 默认值

        if (!this.value && option.defaultValue) {
          if (option.defaultValue === 'now') {
            this.$emit('input', "".concat(year, "-").concat(month, "-").concat(dates));
          } else {
            this.$emit('input', option.defaultValue);
          }
        } // this.changeSaveItemSuc({ type: 'add', ...this.groupInfo })


        var params = _objectSpread(_objectSpread(_objectSpread({
          type: 'add'
        }, this.groupInfo), option.validationEx && {
          validationEx: option.validationEx
        }), option.validationErrorMsg && {
          validationErrorMsg: option.validationErrorMsg
        });

        this.changeSaveItemSuc(params);

        if (this.option.showStyle === 'pastTime') {
          this.pickerOptions = {
            disabledDate: function disabledDate(time) {
              return time.getTime() > Date.now();
            }
          };
        } else if (this.option.showStyle === 'futureTime') {
          this.pickerOptions = {
            disabledDate: function disabledDate(time) {
              return time.getTime() < Date.now();
            }
          };
        } else {
          return false;
        }
      }
    }
  },
  methods: _objectSpread(_objectSpread({}, mapMutations('crf', ['changeSaveItemSuc'])), {}, {
    onChangeHandler: function onChangeHandler(v) {
      this.$emit('input', v);
    },
    showDoubtDialog: function showDoubtDialog() {
      // 打开质疑弹窗
      this.$emit('OpenDoubtDialog');
    }
  }),
  beforeDestroy: function beforeDestroy() {
    this.$emit('input', undefined);
    this.changeSaveItemSuc(_objectSpread({
      type: 'remove'
    }, this.groupInfo));
  }
};