var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.isClick,
          expression: "isClick",
          modifiers: { fullscreen: true, lock: true }
        }
      ],
      attrs: {
        visible: _vm.visible,
        option: _vm.option,
        width: "600px",
        handleBeforeClose: _vm.handleClose,
        "element-loading-background": "rgba(255, 255, 255, 0.3)"
      },
      on: { handleClose: _vm.handleClose, handleOk: _vm.handleOk }
    },
    [
      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
        _vm._v(_vm._s(_vm.title))
      ]),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "100px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "用户名", prop: "userName" } },
            [
              _c("el-input", {
                attrs: {
                  disabled: _vm.disabled,
                  placeholder: "请输入手机号",
                  type: "number",
                  clearable: ""
                },
                model: {
                  value: _vm.ruleForm.userName,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "userName", $$v)
                  },
                  expression: "ruleForm.userName"
                }
              })
            ],
            1
          ),
          !_vm.disabled && _vm.title == "新增用户"
            ? _c(
                "el-form-item",
                { attrs: { label: "密码", prop: "password" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.disabled,
                      placeholder: "请输入密码",
                      "show-password": ""
                    },
                    model: {
                      value: _vm.ruleForm.password,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "password", $$v)
                      },
                      expression: "ruleForm.password"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "真实姓名", prop: "name" } },
            [
              _c("el-input", {
                attrs: {
                  disabled: _vm.disabled,
                  placeholder: "请输入用户名称"
                },
                model: {
                  value: _vm.ruleForm.name,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "name", $$v)
                  },
                  expression: "ruleForm.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "性别", prop: "sex" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.sex,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "sex", $$v)
                    },
                    expression: "ruleForm.sex"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: "男", value: "0" } }),
                  _c("el-radio", { attrs: { label: "女", value: "1" } })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "邮箱", prop: "email" } },
            [
              _c("el-input", {
                attrs: { disabled: _vm.disabled, placeholder: "请输入邮箱" },
                model: {
                  value: _vm.ruleForm.email,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "email", $$v)
                  },
                  expression: "ruleForm.email"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "手机号", prop: "mobile" } },
            [
              _c("el-input", {
                attrs: { disabled: _vm.disabled, placeholder: "请输入手机号" },
                model: {
                  value: _vm.ruleForm.mobile,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "mobile", $$v)
                  },
                  expression: "ruleForm.mobile"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "所属机构", prop: "deptId" } },
            [
              _c("el-cascader", {
                attrs: {
                  filterable: "",
                  disabled: _vm.disabled,
                  options: _vm.deptTree,
                  props: {
                    value: "deptId",
                    label: "deptName",
                    emitPath: false,
                    expandTrigger: "hover",
                    checkStrictly: true
                  },
                  clearable: ""
                },
                model: {
                  value: _vm.ruleForm.deptId,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "deptId", $$v)
                  },
                  expression: "ruleForm.deptId"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "角色", prop: "roleIds" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    disabled: _vm.disabled,
                    multiple: "",
                    placeholder: "请选择"
                  },
                  model: {
                    value: _vm.ruleForm.roleIds,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "roleIds", $$v)
                    },
                    expression: "ruleForm.roleIds"
                  }
                },
                _vm._l(_vm.roleOptions, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  disabled: _vm.disabled,
                  maxlength: "60",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.ruleForm.remark,
                  callback: function($$v) {
                    _vm.$set(_vm.ruleForm, "remark", $$v)
                  },
                  expression: "ruleForm.remark"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [_vm._v("取消")]),
          !_vm.disabled
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleOk } },
                [_vm._v("确定")]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }