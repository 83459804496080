var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-menu-item",
        {
          attrs: { index: _vm.model.code, route: _vm.currentRoute },
          nativeOn: {
            click: function($event) {
              return _vm.closeOpen($event)
            }
          }
        },
        [
          _c("div", { staticClass: "menu-item-wrap whiteBg" }, [
            _c("div", { staticClass: "pane" }, [
              _c("i", {
                staticClass: "iconfont",
                class:
                  _vm.iconMapping[
                    _vm.currentRoute.meta && _vm.currentRoute.meta.icon
                  ]
              }),
              _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v(_vm._s(_vm.model.meta.title))
              ])
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }