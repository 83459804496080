/* eslint-disable */

/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  'map_all': {
    width: 32,
    height: 32,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M870.4 412.8L896 601.6c-80-16-153.6-48-220.8-89.6 54.4-25.6 108.8-57.6 153.6-99.2h41.6zM355.2 566.4L268.8 704l-121.6 3.2L185.6 528c54.4 22.4 112 35.2 169.6 38.4zM137.6 745.6l262.4-3.2 96 108.8c-96-22.4-201.6-44.8-310.4-44.8-22.4 0-41.6 0-60.8 3.2l12.8-64zM560 867.2L419.2 704H300.8l86.4-137.6h22.4c83.2 0 163.2-16 236.8-44.8 76.8 51.2 163.2 86.4 252.8 105.6l16 105.6-89.6 9.6L752 896h-9.6c-51.2 0-112-12.8-182.4-28.8zm464-54.4L793.6 992H784c-12.8 0-25.6 3.2-38.4 3.2-70.4 0-150.4-19.2-236.8-38.4-99.2-22.4-208-51.2-320-51.2-44.8 0-86.4 3.2-128 12.8L0 931.2l134.4-617.6 16-9.6c12.8-6.4 28.8-12.8 41.6-19.2 6.4 9.6 12.8 22.4 22.4 32-16 6.4-32 12.8-44.8 19.2L51.2 876.8C320 819.2 588.8 963.2 768 950.4c25.6-48 86.4-172.8 86.4-172.8l128-12.8-57.6-422.4c-131.2 19.2-268.8-16-400-38.4 6.4-12.8 16-22.4 19.2-35.2 19.2 3.2 41.6 6.4 60.8 9.6 76.8 16 160 28.8 236.8 28.8 28.8 0 54.4-3.2 76.8-6.4l38.4-6.4 67.2 518.4zm-438.4-432c60.8 12.8 124.8 22.4 188.8 28.8-41.6 32-86.4 57.6-134.4 76.8-44.8-32-86.4-73.6-121.6-115.2l67.2 9.6zM419.2 432c22.4-25.6 41.6-44.8 60.8-67.2 38.4 51.2 80 96 131.2 134.4-64 22.4-131.2 32-198.4 32-73.6 0-147.2-12.8-214.4-38.4l25.6-112c9.6-3.2 22.4-9.6 32-12.8 16 19.2 35.2 38.4 57.6 60.8l54.4 54.4 51.2-51.2zM364.8 28.8C278.4 28.8 208 99.2 208 185.6c0 83.2 54.4 134.4 153.6 236.8l6.4 6.4 6.4-6.4C470.4 320 528 265.6 528 185.6c-6.4-86.4-76.8-156.8-163.2-156.8zm0 345.6c-83.2-89.6-118.4-128-118.4-188.8 0-67.2 54.4-118.4 118.4-118.4 67.2 0 118.4 54.4 118.4 118.4 0 60.8-35.2 99.2-118.4 188.8z" _fill="#2c2c2c"/>'
  }
});