var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          staticClass: "upload-Ximg",
          attrs: {
            "before-upload": _vm.beforeAvatarUpload,
            "http-request": _vm.handleImage,
            action: "",
            accept: "image/*",
            "show-file-list": false
          }
        },
        [
          _c("el-button", { attrs: { size: "small", type: "primary" } }, [
            _vm._v("上传脊椎X光图像")
          ])
        ],
        1
      ),
      _vm._l(_vm.measureFormData[_vm.currentMenu].lines, function(line, index) {
        return _c(
          "div",
          { key: index },
          [
            line.end
              ? _c(
                  "div",
                  {
                    staticClass: "line-length",
                    style: {
                      left: line.start.x + "px",
                      top: line.start.y + 40 + "px"
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.measureType === "distance" &&
                              _vm.currentMenu === "SVA",
                            expression:
                              "measureType === 'distance' && currentMenu === 'SVA'"
                          }
                        ],
                        staticClass: "length"
                      },
                      [_vm._v(_vm._s(_vm.getLineLength(line, index)))]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "delete-line",
                        on: {
                          click: function($event) {
                            return _vm.deleteLine(index)
                          }
                        }
                      },
                      [_vm._v("✖")]
                    ),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.currentMenu === "ISH" ||
                              _vm.currentMenu === "CVH",
                            expression:
                              "currentMenu === 'ISH' || currentMenu === 'CVH'"
                          }
                        ],
                        staticStyle: { ransform: "translate(-30px, 20px)" },
                        domProps: { value: line.lineCode },
                        on: {
                          change: function($event) {
                            return _vm.changeLineCode($event, index)
                          }
                        }
                      },
                      [
                        _c("option", { attrs: { value: "a" } }, [_vm._v("a")]),
                        _c("option", { attrs: { value: "b" } }, [_vm._v("b")]),
                        _c("option", { attrs: { value: "c" } }, [_vm._v("c")]),
                        _c("option", { attrs: { value: "d" } }, [_vm._v("d")])
                      ]
                    )
                  ]
                )
              : _vm._e(),
            _vm._l(line.handles, function(handle, handleIndex) {
              return _c("div", {
                key: handleIndex,
                staticClass: "handle",
                style: {
                  left: handle.x + "px",
                  top: handle.y + 50 + "px"
                },
                on: {
                  mousedown: function($event) {
                    return _vm.startDragging($event, line, handleIndex + 1)
                  }
                }
              })
            })
          ],
          2
        )
      }),
      _vm.measureFormData[_vm.currentMenu].referenceLine &&
      _vm.measureFormData[_vm.currentMenu].referenceLine.end
        ? _c(
            "div",
            [
              _c(
                "div",
                {
                  staticClass: "reference-length",
                  style: {
                    left:
                      (_vm.measureFormData[_vm.currentMenu].referenceLine.start
                        .x +
                        _vm.measureFormData[_vm.currentMenu].referenceLine.end
                          .x) /
                        2 +
                      "px",
                    top:
                      (_vm.measureFormData[_vm.currentMenu].referenceLine.start
                        .y +
                        _vm.measureFormData[_vm.currentMenu].referenceLine.end
                          .y) /
                        2 +
                      "px"
                  }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value:
                          _vm.measureFormData[_vm.currentMenu].referenceLength,
                        expression:
                          "measureFormData[currentMenu].referenceLength",
                        modifiers: { number: true }
                      }
                    ],
                    attrs: { type: "number", size: "4", min: "0", step: "0.1" },
                    domProps: {
                      value:
                        _vm.measureFormData[_vm.currentMenu].referenceLength
                    },
                    on: {
                      input: [
                        function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.measureFormData[_vm.currentMenu],
                            "referenceLength",
                            _vm._n($event.target.value)
                          )
                        },
                        _vm.updateMeasurementLabels
                      ],
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  }),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.measureFormData[_vm.currentMenu].unit,
                          expression: "measureFormData[currentMenu].unit"
                        }
                      ],
                      on: {
                        change: [
                          function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.measureFormData[_vm.currentMenu],
                              "unit",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function($event) {
                            return _vm.changeLineunit($event)
                          }
                        ]
                      }
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [_vm._v("none")]),
                      _c("option", { attrs: { value: "mm" } }, [_vm._v("mm")]),
                      _c("option", { attrs: { value: "cm" } }, [_vm._v("cm")]),
                      _c("option", { attrs: { value: "m" } }, [_vm._v("m")]),
                      _c("option", { attrs: { value: "in" } }, [_vm._v("in")])
                    ]
                  )
                ]
              ),
              _vm._l(
                _vm.measureFormData[_vm.currentMenu].referenceLine.handles,
                function(handle, handleIndex) {
                  return _c("div", {
                    key: handleIndex,
                    staticClass: "handle reference-handle",
                    style: {
                      left: handle.x + "px",
                      top: handle.y + 50 + "px"
                    },
                    on: {
                      mousedown: function($event) {
                        return _vm.startDragging(
                          $event,
                          _vm.measureFormData[_vm.currentMenu].referenceLine,
                          handleIndex + 1
                        )
                      }
                    }
                  })
                }
              )
            ],
            2
          )
        : _vm._e(),
      _c("div", { staticClass: "main" }, [
        _c("canvas", {
          ref: "canvas",
          style: {
            cursor:
              _vm.isAddingLine || _vm.isAddingReferenceLine ? "crosshair" : ""
          },
          attrs: { width: "500px", height: "800px" },
          on: { click: _vm.placeLine }
        }),
        _c("div", { staticClass: "main-right" }, [
          _c(
            "div",
            { staticClass: "tab" },
            _vm._l(_vm.menuBtns, function(menu) {
              return _c(
                "span",
                {
                  key: menu.value,
                  class: _vm.currentMenu === menu.value ? "current" : "",
                  on: {
                    click: function($event) {
                      return _vm.changeMeasure(menu)
                    }
                  }
                },
                [_vm._v(_vm._s(menu.name))]
              )
            }),
            0
          ),
          _c("div", { staticClass: "measure-area" }, [
            _c(
              "div",
              { staticClass: "draw-btn" },
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.measureType === "distance",
                        expression: "measureType === 'distance'"
                      }
                    ],
                    attrs: {
                      disabled:
                        _vm.measureFormData[_vm.currentMenu].referenceLine &&
                        "end" in
                          _vm.measureFormData[_vm.currentMenu].referenceLine
                    },
                    on: {
                      click: function($event) {
                        return _vm.startDrawing("reference")
                      }
                    }
                  },
                  [_vm._v(" 绘制参考线 ")]
                ),
                _c(
                  "el-button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.startDrawing("measurement")
                      }
                    }
                  },
                  [_vm._v("绘制测量线段")]
                ),
                _c(
                  "el-button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.removeAll()
                      }
                    }
                  },
                  [_vm._v("清除所有线段")]
                )
              ],
              1
            ),
            _c("div", { staticClass: "measure-result" }, [
              _c("div", [
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.measureFormData[_vm.currentMenu].angle,
                        expression: "measureFormData[currentMenu].angle"
                      }
                    ],
                    staticClass: "plan"
                  },
                  [
                    _c("span", { staticClass: "plan-title" }, [
                      _vm._v("角度：")
                    ]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.measureFormData[_vm.currentMenu].angle))
                    ])
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          (_vm.currentMenu === "ISH" ||
                            _vm.currentMenu === "CVH") &&
                          _vm.measureFormData[_vm.currentMenu].lines.length !=
                            0,
                        expression:
                          "(currentMenu === 'ISH' || currentMenu === 'CVH') && measureFormData[currentMenu].lines.length != 0"
                      }
                    ],
                    staticClass: "plan"
                  },
                  [
                    _c("span", { staticClass: "plan-title" }, [
                      _vm._v("长度：")
                    ]),
                    _c(
                      "span",
                      _vm._l(
                        _vm.measureFormData[_vm.currentMenu].lines,
                        function(len, idx) {
                          return _c(
                            "p",
                            {
                              key: idx,
                              staticStyle: { "margin-left": "48px" }
                            },
                            [
                              _vm._v(
                                _vm._s(len.lineCode) +
                                  "线段长度: " +
                                  _vm._s(len.linelength ? len.linelength : "")
                              )
                            ]
                          )
                        }
                      ),
                      0
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.measureFormData[_vm.currentMenu].diagnosis,
                        expression: "measureFormData[currentMenu].diagnosis"
                      }
                    ],
                    staticClass: "plan"
                  },
                  [
                    _c("span", { staticClass: "plan-title" }, [
                      _vm._v("诊断：")
                    ]),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.measureFormData[_vm.currentMenu].diagnosis)
                      )
                    ])
                  ]
                ),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.measureFormData[_vm.currentMenu].symptom,
                        expression: "measureFormData[currentMenu].symptom"
                      }
                    ],
                    staticClass: "plan"
                  },
                  [
                    _c("span", { staticClass: "plan-title" }, [
                      _vm._v("症状：")
                    ]),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.measureFormData[_vm.currentMenu].symptom)
                      )
                    ])
                  ]
                ),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.measureFormData[_vm.currentMenu].medicalAdvice,
                        expression: "measureFormData[currentMenu].medicalAdvice"
                      }
                    ],
                    staticClass: "plan"
                  },
                  [
                    _c("span", { staticClass: "plan-title" }, [
                      _vm._v("治疗策略：")
                    ]),
                    _c("el-input", {
                      attrs: {
                        type: "textarea",
                        value:
                          _vm.measureFormData[_vm.currentMenu].medicalAdvice,
                        readonly: ""
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ])
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }