function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import ResizeObserver from 'resize-observer-polyfill';
var addEventListener = EventTarget.prototype.addEventListener;

var createHTMLEvent = function createHTMLEvent(type) {
  var event = document.createEvent('HTMLEvents');
  event.initEvent(type);
  return event;
};

export var EventListenerWrapper = function addEventListenerWrapper(type) {
  if (type === 'resize') {
    registerEvent(this);
  }

  addEventListener.apply(this, arguments);
};

function initObserver(callback) {
  var observer = initObserver.observer = initObserver.observer || new ResizeObserver(callback);
  observer.initialized = true;
  return observer;
}

function registerEvent(elm) {
  var observer = initObserver(function (entries) {
    if (observer.initialized) {
      observer.initialized = false;
      return;
    }

    var _iterator = _createForOfIteratorHelper(entries),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var entry = _step.value;
        var event = createHTMLEvent('resize');
        entry.target.dispatchEvent(event);

        if (entry && entry.target && typeof entry.target.onresize === 'function') {
          entry.target.onresize(event);
        } // console.log('dispatch-event-resize')

      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  });
  observer.observe(elm);
}

HTMLElement.prototype.addEventListener = EventListenerWrapper;
Reflect.defineProperty(HTMLElement.prototype, 'onresize', {
  set: function set(handler) {
    this._onresize = handler;

    if (typeof handler !== 'function') {
      return;
    }

    registerEvent(this);
  },
  get: function get() {
    return this._onresize || null;
  }
});