function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import _ from 'lodash';
export function generateTree(list) {
  var key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'id';
  var pkey = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'pid';
  var ckey = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'children';

  var temp = _.cloneDeep(list);

  var res = [];
  temp.forEach(function (item) {
    var pitem = temp.find(function (_pitem) {
      return _pitem[key] === item[pkey];
    });

    if (pitem) {
      if (pitem[ckey]) {
        pitem[ckey].push(item);
      } else {
        pitem[ckey] = [item];
      }
    } else {
      res.push(item);
    }
  });
  return res;
}
export function generateTreeWithOutClone(list) {
  var key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'id';
  var pkey = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'pid';
  var ckey = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'children';
  var res = [];
  list.forEach(function (item) {
    var pitem = list.find(function (_pitem) {
      return _pitem[key] === item[pkey];
    });

    if (pitem) {
      if (pitem[ckey]) {
        pitem[ckey].push(item);
      } else {
        pitem[ckey] = [item];
      }
    } else {
      res.push(item);
    }
  });
  return res;
}
export function generateRoleMenuTree(list) {
  var key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'id';
  var pkey = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'pid';
  var ckey = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'children';

  var temp = _.cloneDeep(list);

  var res = [];
  temp.forEach(function (item) {
    var pitem = temp.find(function (_pitem) {
      return _pitem[key] === item[pkey];
    });

    if (item.type !== 1603) {
      item.checkBox = [{
        name: item.name,
        perm: item.perm,
        id: item.id,
        pid: item.pid,
        type: item.type
      }];

      if (pitem) {
        if (pitem[ckey]) {
          pitem[ckey].push(item);
        } else {
          pitem[ckey] = [item];
        }
      } else {
        res.push(item);
      }
    } else {
      if (pitem) {
        if (pitem.checkBox) {
          pitem.checkBox.push(item);
        } else {
          pitem.checkBox = [item];
        }
      } else {
        res.push(item);
      }
    }
  });
  temp.forEach(function (m) {
    if (m.type === 1002) {
      m._children = m.children && m.children.reduce(function (p, c) {
        return p.concat.apply(p, _toConsumableArray(c.checkBox));
      }, []);
      delete m.children;
    }
  });
  return res;
}
export function cascaderDataTransform(list, id) {
  var key = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'id';
  var pkey = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'pid';
  var res = [];
  var tempId = id;
  var temp;

  while (temp = list.find(function (item) {
    return item[key] === tempId;
  }), temp) {
    res.unshift(temp[key]);
    tempId = temp[pkey];
  }

  return res;
}