//
//
//
//
//
//
//
//
import elCard from '../../Card';
export default {
  components: {
    'el-card': elCard
  },
  props: {
    option: {
      type: Object,
      required: true
    }
  }
};