/* eslint-disable */

/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  'alert': {
    width: 38.406,
    height: 32,
    viewBox: '0 0 1229 1024',
    data: '<defs/><path pid="0" d="M1212.636 916.559L680.21 44.505c-36.177-59.317-94.88-59.317-131.4 0L16.382 916.56C-19.795 975.911 6.485 1024 75.086 1024h1078.847c68.6 0 94.88-48.09 58.703-107.441zM552.222 371.777a62.287 62.287 0 11124.574 0V624.68a62.287 62.287 0 11-124.574 0V371.777zm65.53 506.181a63.277 63.277 0 1162.116-63.277 62.594 62.594 0 01-62.116 63.277zm0 0" _fill="#d81e06"/>'
  }
});