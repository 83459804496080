/* eslint-disable */

/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  'goMap': {
    width: 32,
    height: 32,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M511.997.008a349.693 349.693 0 00-248.894 597.627l248.894 247.582 248.958-247.582A349.693 349.693 0 00511.997.072zm0 505.596a155.999 155.999 0 01-156.51-155.519 155.935 155.935 0 01156.446-155.519 155.999 155.999 0 01156.511 155.52A155.935 155.935 0 01512.125 505.54h-.192zm-156.51 453.34c0 35.904 70.079 65.024 156.51 65.024s156.511-29.12 156.511-65.024-70.08-65.023-156.51-65.023-156.511 29.152-156.511 65.055z" _fill="#1296db"/>'
  }
});