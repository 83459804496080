var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "menu" },
    [
      _c(
        "el-menu",
        {
          staticClass: "top-ul sidebar",
          attrs: {
            mode: "vertical",
            collapse: _vm.isFold,
            "collapse-transition": false,
            "default-active": _vm.defaultActiveId,
            "unique-opened": ""
          }
        },
        [
          _c("div", { staticClass: "sidebar-top" }, [
            _c("div", { class: ["logo", { "logo-collapse": _vm.isFold }] }, [
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isFold,
                    expression: "!isFold"
                  }
                ],
                staticClass: "logo-img",
                attrs: { src: require("../../../assets/img/logo.png") }
              }),
              _c(
                "div",
                {
                  staticClass: "logo_title",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.foldSidebar($event)
                    }
                  }
                },
                [_vm._v("Fozo")]
              ),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isFold,
                    expression: "!isFold"
                  }
                ],
                staticClass: "el-icon-more angle",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.foldSidebar($event)
                  }
                }
              })
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isFold,
                    expression: "!isFold"
                  }
                ],
                staticClass: "crf_info"
              },
              [
                _c("div", { staticClass: "crf_info_title" }, [
                  _c(
                    "div",
                    {
                      staticClass: "er-code",
                      on: {
                        click: function($event) {
                          _vm.openBigCode = true
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: (_vm.createrObj.subject || {}).openId
                            ? require("../../../assets/img/QR-code-bind.png")
                            : require("../../../assets/img/QR-code-unbound.png"),
                          width: "45px",
                          height: "45px"
                        }
                      })
                    ]
                  ),
                  _c("span", [
                    _c(
                      "div",
                      {
                        staticClass: "sub-name",
                        on: {
                          click: function($event) {
                            return _vm.openSubjectDialog(_vm.createrObj.subject)
                          }
                        }
                      },
                      [
                        _c("p", [
                          _vm._v(
                            _vm._s((_vm.createrObj.subject || {}).subjectName)
                          )
                        ])
                      ]
                    ),
                    _c("div", [
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            (_vm.createrObj.subject || {}).identificationCode
                          )
                        )
                      ])
                    ])
                  ])
                ]),
                _c("div", { staticClass: "crf_info_content" }, [
                  _c(
                    "div",
                    {
                      staticClass: "crf_info_item",
                      attrs: { title: (_vm.detail.crf || {}).title }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("../../../assets/img/point_beaufiful@3x.png"),
                          width: "20px"
                        }
                      }),
                      _c("span", [_vm._v("CRF名称")]),
                      _vm._v(" " + _vm._s(_vm.cardCrfTitle) + " ")
                    ]
                  ),
                  _c("div", { staticClass: "crf_info_item" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/img/point_beaufiful@3x.png"),
                        width: "20px"
                      }
                    }),
                    _c("span", [_vm._v("录⼊⼈员 ")]),
                    _vm._v(
                      " " +
                        _vm._s((_vm.createrObj.subjectRecord || {}).creater) +
                        " "
                    )
                  ])
                ])
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isFold,
                    expression: "isFold"
                  }
                ],
                staticClass: "crf_info_fold"
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "sub-name",
                    on: {
                      click: function($event) {
                        return _vm.openSubjectDialog(_vm.createrObj.subject)
                      }
                    }
                  },
                  [
                    _c("p", [
                      _vm._v(_vm._s((_vm.createrObj.subject || {}).subjectName))
                    ])
                  ]
                )
              ]
            )
          ]),
          _c(
            "el-scrollbar",
            {
              class: { "fold-scrollbar": _vm.isFold },
              attrs: { "wrap-class": "scrollbar-wrapper" }
            },
            [
              _c(
                "div",
                [
                  _vm._l(_vm.menuTrees, function(route) {
                    return [
                      !route.children
                        ? _c(
                            "el-menu-item",
                            {
                              key: route.name,
                              class: { "fold-scrollbar": _vm.isFold },
                              attrs: { index: route.name }
                            },
                            [
                              _c("div", { staticClass: "menu-item-wrap" }, [
                                _c(
                                  "span",
                                  { attrs: { slot: "title" }, slot: "title" },
                                  [_vm._v(_vm._s(route.meta.title))]
                                )
                              ])
                            ]
                          )
                        : _c("CrfSubMenu", {
                            key: route.name,
                            attrs: {
                              index: route.name,
                              "menu-info": route,
                              "active-value": _vm.defaultActiveId
                            },
                            on: { changeMenuNum: _vm.handleUpdataMenuId }
                          })
                    ]
                  })
                ],
                2
              )
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShowCrfBack,
                  expression: "isShowCrfBack"
                }
              ],
              staticClass: "returnBack",
              on: { click: _vm.returnBack }
            },
            [
              _c("span", { staticStyle: { "user-select": "none" } }, [
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isFold,
                      expression: "!isFold"
                    }
                  ],
                  attrs: {
                    src: require("../../../assets/img/icon_back@3x.png"),
                    width: "20px"
                  }
                }),
                _vm._v("返回")
              ])
            ]
          )
        ],
        1
      ),
      _c("div", { staticClass: "setting" }),
      _c(
        "el-dialog",
        {
          staticClass: "bigQrcode",
          attrs: {
            title: "患者二维码",
            visible: _vm.openBigCode,
            width: "260px",
            "append-to-body": true
          },
          on: {
            "update:visible": function($event) {
              _vm.openBigCode = $event
            },
            close: _vm.handleClose
          }
        },
        [
          _c(
            "div",
            [
              _c("vue-qr", {
                attrs: {
                  text: _vm.QRcontent,
                  margin: 2,
                  colorDark: "#000",
                  colorLight: "#fff",
                  dotScale: 1,
                  size: 180
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }