var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "menu" },
    [
      _c(
        "el-menu",
        {
          staticClass: "el-menu-vertical-demo sidebar",
          attrs: { "default-active": _vm.$route.name, collapse: _vm.isFold },
          on: {
            open: _vm.handleOpen,
            close: _vm.handleClose,
            select: _vm.menuSelectHandler
          }
        },
        [
          _c(
            "div",
            { class: { logo: true, "logo-collapse": _vm.isFold } },
            [
              _c("el-image", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isFold,
                    expression: "!isFold"
                  }
                ],
                staticClass: "logo-img",
                attrs: { src: require("@/assets/img/logo.png") }
              }),
              _c(
                "div",
                { staticClass: "logo_title", on: { click: _vm.foldSidebar } },
                [_vm._v("Fozo")]
              ),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isFold,
                    expression: "!isFold"
                  }
                ],
                staticClass: "el-icon-more angle",
                on: { click: _vm.foldSidebar }
              })
            ],
            1
          ),
          _c(
            "el-scrollbar",
            {
              class: { "fold-scrollbar": _vm.isFold },
              attrs: { "wrap-class": "scrollbar-wrapper" }
            },
            [
              _vm._l(_vm.menuList, function(route) {
                return [
                  _c("el-menu-" + route.type, {
                    key: route.id,
                    tag: "component",
                    attrs: { model: route }
                  })
                ]
              })
            ],
            2
          ),
          _c(
            "router-link",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShowBack,
                  expression: "isShowBack"
                }
              ],
              staticClass: "returnBack",
              attrs: { to: "/studyList" },
              nativeOn: {
                click: function($event) {
                  return _vm.returnBack($event)
                }
              }
            },
            [
              _c("span", { staticStyle: { "user-select": "none" } }, [
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isFold,
                      expression: "!isFold"
                    }
                  ],
                  attrs: {
                    src: require("../../../assets/img/icon_back@3x.png"),
                    width: "20px"
                  }
                }),
                _vm._v("返回")
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }