import imgFollow from '@/assets/img/icon_visit@3x.png';
import imgSetting from '@/assets/img/icon_set@3x.png';
import imgProject from '@/assets/img/icon_progrom@3x.png';
import imgHome from '@/assets/img/icon_home@3x.png';
import imgTest from '@/assets/img/icon_test@3x.png'; // 白色针管

import imgUser from '@/assets/img/people_icon.jpg';
import imgCalendar from '@/assets/img/icon_calendar@3x.png';
import imgMark from '@/assets/img/icon_mark@3x.png';
import imgCase from '@/assets/img/icon_case@3x.png';
import imgData from '@/assets/img/icon_data@3x.png';
import imgSubject from '@/assets/img/icon_subject.png';
import imgList from '@/assets/img/icon_list@3x.png';
import imgCrf from '@/assets/img/icon_crf@3x.png';
import imgDoing from '@/assets/img/icon_doing@3x.png';
import imgOrganize from '@/assets/img/icon_organize@3x.png';
import imgJob from '@/assets/img/icon_job@3x.png';
import imgMenu from '@/assets/img/icon_meua@3x.png';
import imgPerson from '@/assets/img/icon_person@3x.png';
import imgDay from '@/assets/img/icon_diagnose_day7@3x.png';
import imgCommon from '@/assets/img/icon_common@3x.png';
export default {
  follow: imgFollow,
  setting: imgSetting,
  project: imgProject,
  home: imgHome,
  test: imgTest,
  user: imgUser,
  calendar: imgCalendar,
  mark: imgMark,
  case: imgCase,
  data: imgData,
  subject: imgSubject,
  list: imgList,
  crf: imgCrf,
  doing: imgDoing,
  organize: imgOrganize,
  job: imgJob,
  menu: imgMenu,
  person: imgPerson,
  day: imgDay,
  common: imgCommon
};