/* eslint-disable */
import './alert';
import './crm';
import './dashboard';
import './datasync';
import './goMap';
import './map_all';
import './map_ceng';
import './map_lou';
import './menufold';
import './search';
import './剪刀';
import './工具';