function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
export default {
  props: {
    option: {
      type: Object,
      required: true
    },
    value: {
      type: [String, Number],
      required: false
    }
  },
  data: function data() {
    return {
      list: [],
      defaultValue: '',
      optionReadonly: false
    };
  },
  computed: _objectSpread({}, mapState('study', ['typeOperate'])),
  watch: {
    option: {
      handler: function handler(v) {
        if (v.readOnly) {
          if (v.readOnly === 1) {
            this.optionReadonly = true;
          }
        } else {
          this.optionReadonly = false;
        }

        if (v.defaultValue) {
          this.defaultValue = v.defaultValue;

          if (!this.value) {
            this.clickItem(v.defaultValue);
          }
        }

        var optionsText = v.optionsText,
            optionsValue = v.optionsValue;
        if (!optionsText || !optionsValue) return;
        var textArr = optionsText.split(',');
        var valueArr = optionsValue.split(',');
        this.list = textArr.map(function (item, i) {
          return {
            label: item,
            value: valueArr[i]
          };
        });
      },
      immediate: true
    }
  },
  mounted: function mounted() {
    this.disposeWidth();
  },
  beforeDestroy: function beforeDestroy() {
    this.$emit('input', undefined);
  },
  methods: {
    onChangeHandler: function onChangeHandler(v) {
      this.$emit('input', v);
    },
    clickItem: function clickItem(e) {
      if (!this.typeOperate && !this.optionReadonly) {
        e === this.value ? this.$emit('input', '') : this.$emit('input', e);
      }
    },
    disposeWidth: function disposeWidth() {
      $('.radioGroup').each(function (idx, el) {
        var boxWidth = $(el).width().toFixed(2);
        var onePart = (boxWidth / 6).toFixed(2);
        $(el).children('.radioItem').each(function (i, child) {
          var itemWidth = $(child).width();

          if (itemWidth < onePart) {
            $(child).css('width', onePart + 'px');
          } else if (itemWidth > onePart && itemWidth <= onePart * 2) {
            $(child).css('width', onePart * 2 + 'px');
          } else if (itemWidth > onePart * 2 && itemWidth <= onePart * 3) {
            $(child).css('width', onePart * 3 + 'px');
          } else if (itemWidth > onePart * 3) {
            $(child).css({
              'width': boxWidth + 'px'
            });
            $(child).attr('id', 'overRadio');
          }
        });
      });
    }
  }
};