var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "iq-top-navbar header-top-sticky",
      class: {
        "normal-header": _vm.collapseWidth === "282",
        "fold-header": _vm.collapseWidth === "50",
        "open-subject-list": _vm.crfOpenSubject
      }
    },
    [
      _c("div", { staticClass: "iq-navbar-custom" }, [
        _c("div", { staticClass: "left" }, [
          _c(
            "p",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.studyName,
                  expression: "!studyName "
                }
              ]
            },
            [_vm._v("Fozo智能平台")]
          ),
          _c("p", { attrs: { title: _vm.studyName } }, [
            _vm._v(_vm._s(_vm.studyName))
          ])
        ]),
        _c("ul", { staticClass: "right" }, [
          _c(
            "li",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showCrfIcon,
                  expression: "showCrfIcon"
                }
              ],
              staticClass: "icon-btn",
              attrs: { title: "打印" },
              on: {
                click: _vm.showPrintDialog,
                mouseover: function() {
                  return (_vm.showPrintBtn = true)
                },
                mouseleave: function() {
                  return (_vm.showPrintBtn = false)
                }
              }
            },
            [
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.showPrintBtn,
                    expression: "!showPrintBtn"
                  }
                ],
                staticClass: "el-icon-printer"
              }),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showPrintBtn,
                      expression: "showPrintBtn"
                    }
                  ]
                },
                [_vm._v("打印")]
              )
            ]
          ),
          _vm.showCrfIcon
            ? _c(
                "li",
                {
                  directives: [
                    {
                      name: "auth",
                      rawName: "v-auth",
                      value: "project.subject.dicom",
                      expression: "'project.subject.dicom'"
                    }
                  ],
                  staticClass: "icon-btn",
                  attrs: { title: "查看DICOM" },
                  on: {
                    click: _vm.openDicomUrl,
                    mouseover: function() {
                      return (_vm.showDICOMBtn = true)
                    },
                    mouseleave: function() {
                      return (_vm.showDICOMBtn = false)
                    }
                  }
                },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.showDICOMBtn,
                        expression: "!showDICOMBtn"
                      }
                    ],
                    attrs: {
                      src: require("../../../assets/img/dicom_info.png"),
                      width: "25px"
                    }
                  }),
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showDICOMBtn,
                          expression: "showDICOMBtn"
                        }
                      ]
                    },
                    [_vm._v("查看DICOM")]
                  )
                ]
              )
            : _vm._e(),
          _c(
            "li",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showCrfIcon && _vm.getType(),
                  expression: "showCrfIcon && getType()"
                }
              ],
              staticClass: "icon-btn",
              attrs: { title: "智能识别" },
              on: {
                click: _vm.openImgUploadDialog,
                mouseover: function() {
                  return (_vm.showIdentifyBtn = true)
                },
                mouseleave: function() {
                  return (_vm.showIdentifyBtn = false)
                }
              }
            },
            [
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.showIdentifyBtn,
                    expression: "!showIdentifyBtn"
                  }
                ],
                attrs: {
                  src: require("../../../assets/img/recognize.png"),
                  width: "22px"
                }
              }),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showIdentifyBtn,
                      expression: "showIdentifyBtn"
                    }
                  ]
                },
                [_vm._v("智能识别")]
              )
            ]
          ),
          _c("li", { staticClass: "icon-btn" }, [
            _c("i", {
              class: _vm.fullScreenIcon,
              on: { click: _vm.toggleFullScreen }
            })
          ]),
          _c(
            "li",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.iconShow,
                  expression: "iconShow"
                }
              ],
              staticClass: "icon-btn calendar",
              on: { click: _vm.toCalendar }
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../../assets/img/calendar.png"),
                  width: "25px"
                }
              }),
              _vm.eventCount != 0
                ? _c("span", { staticClass: "bg-danger dots" })
                : _vm._e()
            ]
          ),
          _c(
            "li",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.iconShow,
                  expression: "iconShow"
                }
              ],
              staticClass: "icon-btn",
              on: { click: _vm.enterMeilPage }
            },
            [
              _c("i", { staticClass: "ri-notification-3-fill" }),
              _vm.followCount != 0
                ? _c("span", { staticClass: "bg-primary dots" })
                : _vm._e()
            ]
          ),
          _c(
            "li",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.hidePersonal,
                  expression: "!hidePersonal"
                }
              ],
              staticClass: "personal-center"
            },
            [
              _c(
                "el-popover",
                {
                  attrs: {
                    placement: "bottom",
                    width: "300",
                    "popper-class": "user-popover",
                    trigger: "click"
                  }
                },
                [
                  _c("div", { staticClass: "user-dropdown" }, [
                    _c("div", { staticClass: "title" }, [
                      _c("h5", [_vm._v("你好！ " + _vm._s(_vm.user.name))]),
                      _c("span", [_vm._v(_vm._s(_vm.user.remark))])
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _c("div", { staticClass: "icon" }, [
                        _c("i", { staticClass: "ri-account-box-line" })
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "media-body",
                          on: {
                            click: function($event) {
                              return _vm.showUserDialog({
                                userId: _vm.user.userId,
                                isEdit: false
                              })
                            }
                          }
                        },
                        [
                          _c("h6", [_vm._v("个人信息")]),
                          _c("p", [_vm._v("查看个⼈信息详情")])
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _c("div", { staticClass: "icon" }, [
                        _c("i", { staticClass: "ri-lock-line" })
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "media-body",
                          on: { click: _vm.showUpdatePwd }
                        },
                        [
                          _c("h6", [_vm._v("修改密码")]),
                          _c("p", [_vm._v("重置你的登录密码")])
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _c("div", { staticClass: "icon" }, [
                        _c("i", { staticClass: "ri-profile-line" })
                      ]),
                      _c("div", { staticClass: "media-body" }, [
                        _c("h6", [_vm._v("帮助与反馈")]),
                        _c("p")
                      ])
                    ]),
                    _c(
                      "div",
                      { staticClass: "footer" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", round: "" },
                            on: {
                              click: function($event) {
                                return _vm.handleCommand("logout")
                              }
                            }
                          },
                          [_vm._v("退出登录")]
                        )
                      ],
                      1
                    )
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "user-btn",
                      attrs: { slot: "reference" },
                      slot: "reference"
                    },
                    [
                      _c("img", {
                        staticClass: "user_icon",
                        attrs: {
                          src: require("@/assets/img/user.jpg"),
                          alt: "user"
                        }
                      }),
                      _c("div", { staticClass: "caption" }, [
                        _c("h6", [_vm._v(_vm._s(_vm.user.name))]),
                        _c("span", [_vm._v(_vm._s(_vm.user.remark))])
                      ])
                    ]
                  )
                ]
              )
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }