function _construct(Parent, args, Class) { if (_isNativeReflectConstruct()) { _construct = Reflect.construct; } else { _construct = function _construct(Parent, args, Class) { var a = [null]; a.push.apply(a, args); var Constructor = Function.bind.apply(Parent, a); var instance = new Constructor(); if (Class) _setPrototypeOf(instance, Class.prototype); return instance; }; } return _construct.apply(null, arguments); }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

function _setPrototypeOf(o, p) { _setPrototypeOf = Object.setPrototypeOf || function _setPrototypeOf(o, p) { o.__proto__ = p; return o; }; return _setPrototypeOf(o, p); }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import elCrf from './index.vue';
import { mapState } from 'vuex';
export default {
  components: {
    'el-dynamic': elCrf
  },
  computed: _objectSpread(_objectSpread({}, mapState('study', ['typeOperate'])), {}, {
    sectionLayout: function sectionLayout() {
      return this.getSectionLayout();
    }
  }),
  props: {
    childList: {
      type: Array,
      requrired: false,
      default: function _default() {
        return [];
      }
    },
    value: {
      type: String,
      required: false
    },
    crfRules: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    option: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      list: [],
      optionReadonly: false
    };
  },
  inject: ['getSectionLayout'],
  watch: {
    value: {
      immediate: true,
      handler: function handler(v) {
        if (v === JSON.stringify(this.list) || !v) return;
        this.list = JSON.parse(v);
      }
    },
    list: {
      deep: true,
      immediate: true,
      handler: function handler(v) {
        var _this = this;

        if (v.length != 0) {
          v.forEach(function (allItem, index) {
            var ruleRes = _this.calc(allItem, _this.crfRules);

            allItem = ruleRes;
          });
          var str = JSON.stringify(v);
          if (str !== this.value) this.$emit('input', str);
        }
      }
    },
    option: {
      immediate: true,
      deep: true,
      handler: function handler(option) {
        if (option.readOnly) {
          if (option.readOnly === 1) {
            this.optionReadonly = true;
          }
        } else {
          this.optionReadonly = false;
        }
      }
    }
  },
  mounted: function mounted() {
    if (this.list.length === 0) {
      var listObj = {};

      if (this.childList.length != 0) {
        this.childList.forEach(function (el) {
          listObj[el.name] = undefined;
        });
        this.list.push(listObj);
      }
    }
  },
  methods: {
    addListObj: function addListObj() {
      var listObj = {};

      if (this.childList.length != 0) {
        this.childList.forEach(function (el) {
          listObj[el.name] = undefined;
        });
        this.list.push(listObj);
      }
    },
    delList: function delList(idx) {
      var newArr = this.list.filter(function (el, index) {
        return index != idx;
      });
      var str = JSON.stringify(newArr);
      if (str !== this.value) this.$emit('input', str);
    },
    calc: function calc(data, rules) {
      try {
        Object.keys(rules || {}).forEach(function (r) {
          var paramNames = [];
          var params = [];
          Object.keys(data).forEach(function (k) {
            paramNames.push(k);
            params.push(data[k]);
          });

          var fn = _construct(Function, paramNames.concat(["try { ".concat(rules[r], " } catch (e) {}")])); // eslint-disable-line


          var val = fn.apply(void 0, params) + '';

          if (val !== 'NaN' && val !== 'undefined') {
            data[r] = val;
          }
        });
        return data;
      } catch (e) {}
    },
    getCols: function getCols(item) {
      var cols = 1;

      if (item.option && item.option.cols) {
        cols = item.option.cols / this.sectionLayout.crfCols;
      } // if (item.option && item.option.cols) cols = item.option.cols == 1 ? 2 : 1


      return 24 * cols;
    }
  }
};