//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//  <iframe src="about:blank" frameBorder="0" marginHeight="0" marginWidth="0" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 99"></iframe>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    option: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    handleBeforeClose: {
      type: Function,
      default: function _default() {
        return function () {};
      }
    },
    okText: {
      type: String,
      default: '确 定'
    },
    loading: {
      type: Boolean,
      default: false
    },
    isOcx: {
      type: Boolean,
      default: false
    },
    noheader: {
      type: Boolean,
      default: false
    }
  }
};