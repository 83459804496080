var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sidebar-main-menu" }, [
    _c(
      "div",
      { staticClass: "wrapper" },
      [
        _c("CrfSidebar"),
        _c("Navbar", { attrs: { hidePersonal: true } }),
        _c("router-view")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }