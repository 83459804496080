var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "upload-type" },
      [
        _c("label", [_vm._v("识别方式：")]),
        _c(
          "el-radio-group",
          {
            model: {
              value: _vm.uploadType,
              callback: function($$v) {
                _vm.uploadType = $$v
              },
              expression: "uploadType"
            }
          },
          [
            _c("el-radio", { attrs: { label: 1 } }, [_vm._v("图片文件")]),
            _c("el-radio", { attrs: { label: 2 } }, [_vm._v("高拍仪")])
          ],
          1
        )
      ],
      1
    ),
    _vm.uploadType === 1
      ? _c("div", { staticClass: "photo" }, [
          _vm.identifyObj.length != 0
            ? _c(
                "div",
                { staticClass: "photo-choose" },
                [
                  _c("label", [_vm._v("选择模板：")]),
                  _c(
                    "el-select",
                    {
                      staticClass: "select-type",
                      attrs: { placeholder: "请选择定制模板" },
                      model: {
                        value: _vm.customRecord,
                        callback: function($$v) {
                          _vm.customRecord = $$v
                        },
                        expression: "customRecord"
                      }
                    },
                    _vm._l(_vm.identifyObj, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "photo-choose" },
            [
              _c("label", [_vm._v("上传图片：")]),
              _c(
                "el-upload",
                {
                  staticClass: "upload-report",
                  attrs: {
                    action: "/",
                    accept: "image/*",
                    "before-upload": _vm.handleBeforeUpload,
                    "http-request": _vm.handlefileUpload,
                    "show-file-list": false
                  }
                },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "small", type: "primary" } },
                    [_vm._v("上传图片开始识别")]
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      : _vm.uploadType === 2
      ? _c(
          "div",
          { staticClass: "gaopaiyi" },
          [
            _c(
              "div",
              [
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.openCamera } },
                  [_vm._v("打开摄像头")]
                ),
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.takePhoto } },
                  [_vm._v("拍照")]
                ),
                _vm.identifyObj.length != 0
                  ? _c(
                      "el-select",
                      {
                        staticClass: "select-type",
                        attrs: { placeholder: "请选择定制模板" },
                        model: {
                          value: _vm.customRecord,
                          callback: function($$v) {
                            _vm.customRecord = $$v
                          },
                          expression: "customRecord"
                        }
                      },
                      _vm._l(_vm.identifyObj, function(item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id }
                        })
                      }),
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-button",
                  {
                    staticClass: "select-button",
                    on: { click: _vm.toDiscern }
                  },
                  [_vm._v("上传识别")]
                )
              ],
              1
            ),
            _c("img", {
              staticClass: "gaopaiyi-camera",
              attrs: { src: _vm.cameraVideo }
            }),
            _c("el-image", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.cameraPhoto,
                  expression: "cameraPhoto"
                }
              ],
              staticClass: "gaopaiyi-photo",
              attrs: {
                src: _vm.cameraPhoto,
                "preview-src-list": [_vm.cameraPhoto]
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }