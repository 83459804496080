var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "span",
        { staticStyle: { width: "100%" } },
        [
          _vm.isNotFormComponent
            ? [
                _c(
                  "el-crf-" + _vm.option.type,
                  {
                    tag: "component",
                    attrs: {
                      option: Object.assign(
                        {},
                        { title: _vm.option.title },
                        _vm.option.option
                      )
                    },
                    model: {
                      value: _vm.formData[_vm.option.name],
                      callback: function($$v) {
                        _vm.$set(_vm.formData, _vm.option.name, $$v)
                      },
                      expression: "formData[option.name]"
                    }
                  },
                  [
                    _c(
                      "el-row",
                      { staticClass: "col-box", attrs: { gutter: 48 } },
                      [
                        _vm.option.children
                          ? _vm._l(_vm.option.children, function($item) {
                              return _c(
                                "el-col",
                                {
                                  key: $item.id,
                                  style: _vm.crfHidden[$item.name]
                                    ? "display: none"
                                    : "",
                                  attrs: { span: _vm.getCols($item) }
                                },
                                [
                                  _c("el-crf", {
                                    attrs: {
                                      option: $item,
                                      formData: _vm.formData,
                                      crfHidden: _vm.crfHidden,
                                      crfRules: _vm.crfRules,
                                      echoUnites: _vm.echoUnites
                                    }
                                  })
                                ],
                                1
                              )
                            })
                          : _vm._e()
                      ],
                      2
                    )
                  ],
                  1
                ),
                _vm.option.option && _vm.option.option.rightItemText
                  ? _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "top-start",
                          title: "",
                          width: "200",
                          trigger: "hover",
                          content: _vm.option.option.rightItemText
                        }
                      },
                      [
                        _vm.option.option && _vm.option.option.rightItemText
                          ? _c("img", {
                              staticStyle: {
                                "margin-left": "10px",
                                "margin-top": "12px"
                              },
                              attrs: {
                                slot: "reference",
                                src:
                                  _vm.iconId == _vm.option.id
                                    ? require("../../../assets/img/info-hover.png")
                                    : require("../../../assets/img/info.png"),
                                width: "16px"
                              },
                              on: {
                                mouseenter: function($event) {
                                  return _vm.overIcon(_vm.option.id)
                                },
                                mouseleave: function($event) {
                                  return _vm.offIcon()
                                }
                              },
                              slot: "reference"
                            })
                          : _vm._e()
                      ]
                    )
                  : _vm._e()
              ]
            : _vm.option.type == "group"
            ? [
                _c("el-crf-group", {
                  attrs: {
                    visible: _vm.visible,
                    option: Object.assign(
                      {},
                      { title: _vm.option.title, name: _vm.option.name },
                      _vm.option.option
                    ),
                    childList: _vm.option.children,
                    crfRules: _vm.crfRules
                  },
                  model: {
                    value: _vm.formData[_vm.option.name],
                    callback: function($$v) {
                      _vm.$set(_vm.formData, _vm.option.name, $$v)
                    },
                    expression: "formData[option.name]"
                  }
                })
              ]
            : [
                _vm.option.type === "link"
                  ? _c(
                      "el-form-item",
                      [
                        _c("div", { staticClass: "option-title" }, [
                          _c("span", [_vm._v("链接")])
                        ]),
                        _c(
                          "el-link",
                          {
                            attrs: {
                              type: "primary",
                              href:
                                _vm.formData[_vm.option.name] ||
                                _vm.option.option.defaultValue,
                              target: "_blank"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.option.title ||
                                  _vm.formData[_vm.option.name] ||
                                  _vm.option.option.defaultValue
                              )
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.option.type !== "link"
                  ? _c(
                      "el-form-item",
                      {
                        class: [
                          {
                            "horizontal-item": _vm.layoutFuc(
                              _vm.sectionLayout.itemLayout,
                              _vm.option.option.itemLayout
                            )
                          }
                        ],
                        staticStyle: { "margin-bottom": "6px" },
                        attrs: {
                          prop: _vm.option.name,
                          rules: _vm.validateRules
                        }
                      },
                      [
                        _c("div", { staticClass: "option-title" }, [
                          _c(
                            "div",
                            [
                              _vm.option.option.required == 1
                                ? _c("img", {
                                    attrs: {
                                      src: require("../../../assets/img/icon-required.png")
                                    }
                                  })
                                : _vm._e(),
                              _c("label", [_vm._v(_vm._s(_vm.option.title))]),
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "top-start",
                                    title: "",
                                    width: "100",
                                    trigger: "hover",
                                    content: _vm.option.option.rightItemText
                                  }
                                },
                                [
                                  _vm.option.option.rightItemText
                                    ? _c("img", {
                                        attrs: {
                                          slot: "reference",
                                          src:
                                            _vm.iconId == _vm.option.id
                                              ? require("../../../assets/img/info-hover.png")
                                              : require("../../../assets/img/info.png"),
                                          width: "16px"
                                        },
                                        on: {
                                          mouseenter: function($event) {
                                            return _vm.overIcon(_vm.option.id)
                                          },
                                          mouseleave: function($event) {
                                            return _vm.offIcon()
                                          }
                                        },
                                        slot: "reference"
                                      })
                                    : _vm._e()
                                ]
                              )
                            ],
                            1
                          )
                        ]),
                        _c("div", { staticClass: "el-component" }, [
                          _c(
                            "div",
                            { style: "width:" + _vm.option.option.width + "%" },
                            [
                              _c("el-crf-" + _vm.option.type, {
                                tag: "component",
                                attrs: {
                                  option: Object.assign(
                                    {},
                                    { itemName: _vm.option.name },
                                    _vm.option.option
                                  ),
                                  units: _vm.echoUnites[_vm.option.name],
                                  indicantValue: _vm.indicantValue,
                                  showStartDoubtIcon: _vm.showDoubtIcon,
                                  showReplyDoubtIcon: _vm.isReplayDoubt(
                                    _vm.option.name
                                  ),
                                  groupInfo: Object.assign(
                                    {},
                                    { name: _vm.option.name },
                                    _vm.groupInfo
                                  )
                                },
                                on: {
                                  inputUnits: function(v) {
                                    return _vm.storeInputUnits(
                                      v,
                                      _vm.option.name
                                    )
                                  },
                                  OpenDoubtDialog: function($event) {
                                    return _vm.openDoubtDialog(_vm.option)
                                  }
                                },
                                model: {
                                  value: _vm.formData[_vm.option.name],
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, _vm.option.name, $$v)
                                  },
                                  expression: "formData[option.name]"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm.option.children
                  ? _c(
                      "el-row",
                      {
                        class:
                          (_vm.option.depth + 1) % 2 === 0
                            ? "even-item"
                            : "odd-item",
                        attrs: { align: "top" }
                      },
                      _vm._l(_vm.option.children, function($item) {
                        return _c(
                          "el-col",
                          {
                            key: $item.id,
                            ref: "groupItem",
                            refInFor: true,
                            attrs: { span: _vm.getCols($item) }
                          },
                          [
                            _c("el-crf", {
                              attrs: {
                                option: $item,
                                formData: _vm.formData,
                                crfHidden: _vm.crfHidden,
                                crfRules: _vm.crfRules,
                                echoUnites: _vm.echoUnites,
                                groupInfo: _vm.groupInfo
                              }
                            })
                          ],
                          1
                        )
                      }),
                      1
                    )
                  : _vm._e()
              ]
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }