function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState, mapMutations } from 'vuex';
export default {
  props: {
    value: {
      type: [String, Number, Date],
      required: false
    },
    option: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      }
    },
    showStartDoubtIcon: {
      type: Boolean,
      default: false
    },
    showReplyDoubtIcon: {
      type: Boolean,
      default: false
    },
    groupInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState('study', ['typeOperate'])), mapState('crf', ['detail'])), {}, {
    showReplyIcon: function showReplyIcon() {
      var _this = this;

      var groupType = null;

      if (this.groupInfo.groupName) {
        if (this.detail.crfDoubts.length != 0) {
          groupType = this.detail.crfDoubts.some(function (item) {
            return item.itemName === _this.groupInfo.name && item.groupIndex === _this.groupInfo.valueIndex;
          });
        }
      } else {
        groupType = true;
      }

      return this.showReplyDoubtIcon && groupType;
    }
  }),
  data: function data() {
    return {
      optionReadonly: false,
      valueFormat: 'yyyy-MM-dd',
      pickerOptions: null
    };
  },
  watch: {
    option: {
      immediate: true,
      deep: true,
      handler: function handler(option) {
        if (option.readOnly) {
          if (option.readOnly === 1) {
            this.optionReadonly = true;
          }
        } else {
          this.optionReadonly = false;
        } // 格式化


        if (option.itemType === 'date') {
          this.valueFormat = 'yyyy-MM-dd';
        } else if (option.itemType === 'datetime') {
          this.valueFormat = 'yyyy-MM-dd HH:mm:ss';
        }

        if ('format' in option) {
          switch (option.format) {
            case 'year':
              this.valueFormat = 'yyyy';
              break;

            case 'month':
              this.valueFormat = 'yyyy-MM';
              break;

            case 'Hour':
              this.valueFormat = 'yyyy-MM-dd HH';
              break;

            case 'Minute':
              this.valueFormat = 'yyyy-MM-dd HH:mm';
              break;

            default:
              this.valueFormat = 'yyyy-MM-dd';
              break;
          }
        } // 默认值
        // 提取公共日期部分


        if (!this.value && option.defaultValue) {
          if (option.defaultValue === 'now') {
            var now = new Date();
            var year = now.getFullYear();
            var month = String(now.getMonth() + 1).padStart(2, '0');
            var day = String(now.getDate()).padStart(2, '0');
            var datePart = "".concat(year, "-").concat(month, "-").concat(day);

            if (this.option.itemType === 'date') {
              this.$emit('input', datePart);
            } else if (this.option.itemType === 'datetime') {
              var hours = String(now.getHours()).padStart(2, '0');
              var minutes = String(now.getMinutes()).padStart(2, '0');
              var seconds = String(now.getSeconds()).padStart(2, '0');
              this.$emit('input', "".concat(datePart, " ").concat(hours, ":").concat(minutes, ":").concat(seconds));
            }
          } else {
            this.$emit('input', option.defaultValue);
          }
        } // this.changeSaveItemSuc({ type: 'add', ...this.groupInfo })


        var params = _objectSpread(_objectSpread(_objectSpread({
          type: 'add'
        }, this.groupInfo), option.validationEx && {
          validationEx: option.validationEx
        }), option.validationErrorMsg && {
          validationErrorMsg: option.validationErrorMsg
        });

        this.changeSaveItemSuc(params);

        if (option.showStyle === 'pastTime') {
          this.pickerOptions = {
            disabledDate: function disabledDate(time) {
              return time.getTime() > Date.now();
            }
          };
        } else if (option.showStyle === 'futureTime') {
          this.pickerOptions = {
            disabledDate: function disabledDate(time) {
              return time.getTime() < Date.now();
            }
          };
        } else {
          return false;
        }
      }
    }
  },
  methods: _objectSpread(_objectSpread({}, mapMutations('crf', ['changeSaveItemSuc'])), {}, {
    onChangeHandler: function onChangeHandler(v) {
      this.$emit('input', v);
    },
    showDoubtDialog: function showDoubtDialog() {
      // 打开质疑弹窗
      this.$emit('OpenDoubtDialog');
    }
  }),
  beforeDestroy: function beforeDestroy() {
    this.$emit('input', undefined);
    this.changeSaveItemSuc(_objectSpread({
      type: 'remove'
    }, this.groupInfo));
  }
};