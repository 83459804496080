function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import getNet from '@/utils/reloadSide.js'
import $ from 'jquery';
import { mapState } from 'vuex';
export default {
  props: {
    value: {
      type: [Array, String, Number],
      required: false,
      default: function _default() {
        return [];
      }
    },
    option: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      }
    }
  },
  computed: _objectSpread({}, mapState('study', ['typeOperate'])),
  data: function data() {
    return {
      list: [],
      current: [],
      addition: '',
      isAddition: false,
      checkboxValue: [],
      emitList: [{}],
      optionReadonly: false
    };
  },
  watch: {
    value: {
      immediate: true,
      handler: function handler(v) {
        if (!v) return; // 小程序过来的数据这里可能有点问题

        if (typeof v === 'string') {
          var emitVal;

          if (v.indexOf('[') != -1) {
            emitVal = JSON.parse(v);
          } else {
            if (v.length == 1) {
              emitVal = [v];
            } else {
              emitVal = v.split(',');
            }
          }

          this.$emit('input', emitVal);
        } else if (typeof v === 'number') {
          v = v.toString();
          this.$emit('input', [v]);
        }

        this.current = []; // const valueArr = this.option.optionsValue.split(',')

        var valueArr = this.option.optionsValue.split(',');

        for (var i = 0; i < v.length; i++) {
          for (var j = 0; j < valueArr.length; j++) {
            if (v[i] === valueArr[j]) {
              this.current.push("[".concat(this.no, "-").concat(j, "]"));
            }
          }
        }

        if (this.current.length != 0) {
          for (var _i = 0; _i < this.current.length; _i++) {
            // 其他
            var idx = this.current[_i].slice(1, -1);

            idx = idx.split('-')[1];

            if (this.option.addition && this.option.additionIndex == idx) {
              this.addition = this.option.addition;
              this.isAddition = true;
              break;
            } else {
              this.isAddition = false;
            }
          }
        } else {
          this.isAddition = false;
        }
      }
    },
    option: {
      handler: function handler(v) {
        var _this = this;

        if (v.readOnly) {
          if (v.readOnly === 1) {
            this.optionReadonly = true;
          }
        } else {
          this.optionReadonly = false;
        }

        var _ref = v || {},
            optionsValue = _ref.optionsValue,
            optionsText = _ref.optionsText;

        var valueArr = optionsValue.split(',');
        var labelArr = optionsText.split(',');
        var list = [];
        valueArr.forEach(function (item, i) {
          list.push({
            label: labelArr[i],
            value: item,
            name: v.name + i
          });
          if (item === _this.value) _this.current.push("[".concat(_this.no, "-").concat(i, "]"));
        });
        this.list = list;
      },
      immediate: true
    }
  },
  mounted: function mounted() {
    this.disposeWidth();
  },
  methods: {
    onChangeHandler: function onChangeHandler(v) {
      this.$emit('input', v);
    },
    disposeWidth: function disposeWidth() {
      $('.checkBox-cover').each(function (idx, el) {
        var boxWidth = $(el).width().toFixed(2);
        var onePart = (boxWidth / 6).toFixed(2);
        $(el).find('.checkItem').each(function (i, child) {
          var itemWidth = $(child).width();

          if (itemWidth < onePart) {
            $(child).css('width', onePart + 'px');
          } else if (itemWidth > onePart && itemWidth <= onePart * 2) {
            $(child).css('width', onePart * 2 + 'px');
          } else if (itemWidth > onePart * 2 && itemWidth <= onePart * 3) {
            $(child).css('width', onePart * 3 + 'px');
          } else if (itemWidth > onePart * 3) {
            $(child).css('width', boxWidth + 'px');
          }
        });
      });
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.$emit('input', undefined);
  }
};