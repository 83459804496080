var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          staticClass: "upload-file",
          attrs: {
            action: "/",
            "http-request": _vm.onUploadHandler,
            "file-list": _vm.fileList,
            "on-remove": _vm.handleRemove,
            "on-change": _vm.handleChangeFileList,
            "show-file-list": false
          }
        },
        [
          _c("el-button", { attrs: { slot: "default" }, slot: "default" }, [
            _vm._v("上传文件")
          ])
        ],
        1
      ),
      _c(
        "ul",
        { staticClass: "upload_list" },
        _vm._l(_vm.fileList, function(file, idx) {
          return _c("li", { key: idx, staticClass: "upload_list_file" }, [
            _c("div", { staticClass: "upload_list_file_title" }, [
              _c("i", {
                staticClass: "el-icon-document upload_list_file_title_icon"
              }),
              _c("span", [_vm._v(_vm._s(file.name))])
            ]),
            _c("div", { staticClass: "upload_list_file_tools" }, [
              _c("span", { staticClass: "operation" }, [
                _c("i", {
                  staticClass: "el-icon-download",
                  on: {
                    click: function($event) {
                      return _vm.handleDownload(file)
                    }
                  }
                }),
                _c("i", {
                  staticClass: "el-icon-close",
                  on: {
                    click: function($event) {
                      return _vm.handleRemove(file)
                    }
                  }
                })
              ]),
              _c("span", { staticClass: "status" }, [
                file.status === "ready"
                  ? _c("i", { staticClass: "el-icon-loading" })
                  : _vm._e(),
                file.status === "success"
                  ? _c("i", { staticClass: "el-icon-check" })
                  : _vm._e()
              ])
            ])
          ])
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }