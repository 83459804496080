var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-card" },
    [
      _c(
        "div",
        {
          class: [
            {
              "horizontal-item":
                _vm.sectionLayout.itemLayout === "horizontal" &&
                _vm.option.showStyle !== "form"
            },
            "crf-group_header"
          ]
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.option.title))]),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                disabled: _vm.optionReadonly || _vm.typeOperate
              },
              on: { click: _vm.addListObj }
            },
            [_vm._v("新增一条")]
          )
        ],
        1
      ),
      _vm._l(_vm.list, function(listObj, index) {
        return _c(
          "el-form",
          {
            key: index,
            ref: "listForm",
            refInFor: true,
            staticClass: "list-form",
            class:
              _vm.childList[0] &&
              _vm.childList[0].depth &&
              Number(_vm.childList[0].depth) % 2 === 0
                ? "even-item"
                : "odd-item",
            attrs: { "label-position": "left", model: listObj }
          },
          [
            _c(
              "p",
              { staticClass: "close-btn" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "del-btn",
                    attrs: { disabled: _vm.optionReadonly || _vm.typeOperate },
                    on: {
                      click: function($event) {
                        return _vm.delList(index)
                      }
                    }
                  },
                  [_vm._v("删除")]
                )
              ],
              1
            ),
            _c(
              "el-row",
              { attrs: { gutter: 20 } },
              _vm._l(_vm.childList, function(item) {
                return _c(
                  "el-col",
                  { key: item.name, attrs: { span: _vm.getCols(item) } },
                  [
                    _c("el-dynamic", {
                      attrs: { option: item, formData: listObj },
                      model: {
                        value: listObj[item.name],
                        callback: function($$v) {
                          _vm.$set(listObj, item.name, $$v)
                        },
                        expression: "listObj[item.name]"
                      }
                    })
                  ],
                  1
                )
              }),
              1
            )
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }