import elCheckbox from './checkbox.vue'; // import elDate from './date.vue'
// import elDateTime from './datetime.vue'

import elImg from './img.vue';
import elRadio from './radio.vue';
import elSelect from './select.vue';
import elSlider from './slider.vue';
import elTable from './table.vue';
import elText from './text.vue';
import elTextarea from './textarea.vue';
import elVideo from './video.vue';
import elTime from './time.vue';
import elList from './list.vue';
import elmultiSelect from './multiSelect.vue';
import elint from './int.vue';
import elreal from './real.vue';
import elSection from './section.vue';
import elGroup from './group.vue';
import elTitle from './title.vue';
import elCrf from './index.vue';
import elFile from './file.vue';
import ImgRecognition from './imageRecognition.vue';
import imageMeasure from './imageMeasure.vue';
import downTime from './pullDownTime.vue';
import elDatePicker from './datepicker.vue';
import Vue from 'vue';
var components = {
  'el-crf': elCrf,
  'el-crf-checkbox': elCheckbox,
  // 'el-crf-date': elDate,
  // 'el-crf-datetime': elDateTime,
  'el-crf-date': elDatePicker,
  'el-crf-datetime': elDatePicker,
  'el-crf-img': elImg,
  'el-crf-radio': elRadio,
  'el-crf-select': elSelect,
  'el-crf-slider': elSlider,
  'el-crf-table': elTable,
  'el-crf-text': elText,
  'el-crf-textarea': elTextarea,
  'el-crf-video': elVideo,
  'el-crf-time': elTime,
  'el-crf-list': elList,
  'el-crf-multiSelect': elmultiSelect,
  'el-crf-int': elint,
  'el-crf-real': elreal,
  'el-crf-section': elSection,
  'el-crf-group': elGroup,
  'el-crf-title': elTitle,
  'el-crf-file': elFile,
  'el-crf-imageRecognition': ImgRecognition,
  'el-crf-imageMeasure': imageMeasure,
  'el-crf-pullDownTime': downTime
};
Object.keys(components).forEach(function (k) {
  Vue.component(k, components[k]);
});