var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.isClick,
          expression: "isClick",
          modifiers: { fullscreen: true, lock: true }
        }
      ],
      attrs: {
        visible: _vm.visible,
        option: _vm.option,
        width: "650px",
        handleBeforeClose: _vm.hide,
        "element-loading-background": "rgba(255, 255, 255, 0.3)"
      },
      on: { handleClose: _vm.hide, handleOk: _vm.handleOk }
    },
    [
      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
        _vm._v(_vm._s(_vm.title))
      ]),
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _vm.formConfig.identificationCode.display === 1 && _vm.showIdCode
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.formConfig.identificationCode.header,
                    prop: "identificationCode",
                    rules: _vm.rules.identificationCode
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.forbid || _vm.disabledIDCode,
                      placeholder: "请输入"
                    },
                    model: {
                      value: _vm.form.identificationCode,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "identificationCode", $$v)
                      },
                      expression: "form.identificationCode"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.formConfig.subjectName.display === 1
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.formConfig.subjectName.header,
                    prop: "subjectName",
                    rules: _vm.rules.subjectName
                  }
                },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.forbid, placeholder: "请输入" },
                    model: {
                      value: _vm.form.subjectName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "subjectName", $$v)
                      },
                      expression: "form.subjectName"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.formConfig.gender.display === 1
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.formConfig.gender.header,
                    prop: "gender",
                    rules: _vm.rules.gender
                  }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { disabled: _vm.forbid },
                      model: {
                        value: _vm.form.gender,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "gender", $$v)
                        },
                        expression: "form.gender"
                      }
                    },
                    [
                      _c("el-radio-button", { attrs: { label: 1 } }, [
                        _vm._v("男")
                      ]),
                      _c("el-radio-button", { attrs: { label: 0 } }, [
                        _vm._v("女")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.param.displayCrfColumn === 0
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "疾病类型",
                    prop: "diseaseCode",
                    rules: _vm.rules.diseaseCode
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { disabled: _vm.forbid, placeholder: "请选择" },
                      model: {
                        value: _vm.form.diseaseCode,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "diseaseCode", $$v)
                        },
                        expression: "form.diseaseCode"
                      }
                    },
                    _vm._l(_vm.param.studyDiseases, function(dise) {
                      return _c("el-option", {
                        key: dise.id,
                        attrs: {
                          label: dise.diseaseName,
                          value: dise.diseaseCode
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.formConfig.birth.display === 1
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.formConfig.birth.header,
                    prop: "birth",
                    rules: _vm.rules.birth
                  }
                },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      type: "date",
                      disabled: _vm.forbid,
                      "value-format": "yyyy-MM-dd",
                      placeholder: "选择日期",
                      "picker-options": _vm.expireTimeOPtion
                    },
                    on: { change: _vm.getAge },
                    model: {
                      value: _vm.form.birth,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "birth", $$v)
                      },
                      expression: "form.birth"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.formConfig.age.display === 1
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.formConfig.age.header,
                    prop: "age",
                    rules: _vm.rules.age
                  }
                },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { disabled: _vm.forbid, placeholder: "请输入" },
                      model: {
                        value: _vm.form.age,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "age", $$v)
                        },
                        expression: "form.age"
                      }
                    },
                    [_c("template", { slot: "append" }, [_vm._v("岁")])],
                    2
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.formConfig.mobile.display === 1
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.formConfig.mobile.header,
                    prop: "mobile",
                    rules: _vm.rules.mobile
                  }
                },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.forbid, placeholder: "请输入" },
                    model: {
                      value: _vm.form.mobile,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "mobile", $$v)
                      },
                      expression: "form.mobile"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._l(_vm.modelArr, function(fmd, index) {
            return _c("span", { key: index }, [
              fmd.type === "title"
                ? _c("div", [
                    _c("span", { staticClass: "el-crf-title" }, [
                      _vm._v(_vm._s(fmd.title))
                    ])
                  ])
                : _c(
                    "div",
                    [
                      _vm.calcTrig(fmd)
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: fmd.title,
                                prop: fmd.name,
                                rules: _vm.validateRules[index]
                              }
                            },
                            [
                              _c("el-crf-" + fmd.type, {
                                tag: "component",
                                attrs: { option: fmd.option },
                                on: {
                                  inputUnits: function(v) {
                                    return _vm.storeInputUnits(v, fmd.name)
                                  }
                                },
                                model: {
                                  value: _vm.form[fmd.name],
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, fmd.name, $$v)
                                  },
                                  expression: "form[fmd.name]"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
            ])
          })
        ],
        2
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("el-button", { on: { click: _vm.hide } }, [_vm._v("取消")]),
          _vm.operation === "add" || _vm.operation === "edit"
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleOk } },
                [_vm._v("确认")]
              )
            : _vm._e(),
          _vm.operation === "crflook"
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleEdit } },
                [_vm._v("编辑")]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }