//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'Plane',
  props: {
    selections: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    searchRAttr: {
      type: Object,
      default: function _default() {
        return {
          span: 24
        };
      }
    },
    searchLAttr: {
      type: Object,
      default: function _default() {
        return {
          span: 24
        };
      }
    },
    noSearch: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clearHandler: function clearHandler() {
      this.$emit('clearSelection');
    }
  }
};