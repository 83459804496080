//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import elList from './list.vue';
import elTable from './table.vue';
import elForm from './form.vue';
export default {
  components: {
    elList: elList,
    elTable: elTable,
    elForm: elForm
  },
  props: {
    option: {
      type: Object,
      required: true
    },
    childList: {
      type: Array,
      required: false,
      value: function value() {
        return [];
      }
    },
    crfRules: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    value: {
      type: String,
      required: false,
      default: ''
    },
    visible: {
      type: Boolean,
      require: false
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    onInputHandler: function onInputHandler(v) {
      this.$emit('input', v);
    }
  }
};