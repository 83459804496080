var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-submenu",
        {
          staticClass: "menu-item-wrap",
          attrs: { index: _vm.menuInfo.name, route: _vm.menuInfo }
        },
        [
          _c("template", { slot: "title" }, [
            _c("img", {
              attrs: {
                src: require("../../../assets/img/icon_diagnose_day7@3x.png"),
                width: "18px"
              }
            }),
            _c(
              "span",
              {
                class:
                  _vm.menuInfo.children.length === 1
                    ? (_vm.isOne = true)
                    : (_vm.isOne = false),
                attrs: { title: _vm.menuInfo.meta.title }
              },
              [_vm._v(_vm._s(_vm.menuInfo.meta.title))]
            )
          ]),
          _vm._l(
            _vm.menuInfo.children.filter(function(i) {
              return i.meta && !i.meta.hidden
            }) || [],
            function(item, index) {
              return [
                !item.children || item.children.length === 0
                  ? _c(
                      "el-menu-item",
                      {
                        key: item.name,
                        class: {
                          "menu-active": _vm.isActive(item.name),
                          "is-one": _vm.isOne
                        },
                        attrs: { index: item.name },
                        nativeOn: {
                          click: function($event) {
                            return _vm.handleMenuClick(item, index)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "whiteBg" }, [
                          _c("div", { staticClass: "crfTitle" }, [
                            item.repeat === 1
                              ? _c("img", {
                                  staticStyle: { "margin-right": "5px" },
                                  attrs: {
                                    src: require("../../../assets/img/icon_common@3x.png"),
                                    width: "18px",
                                    heigh: "18px"
                                  }
                                })
                              : _c("img", {
                                  staticStyle: {
                                    "margin-right": "5px",
                                    padding: "1px"
                                  },
                                  attrs: {
                                    src: require("../../../assets/img/multiple-file.png"),
                                    width: "18px",
                                    height: "18px"
                                  }
                                }),
                            _c("span", [
                              _c(
                                "span",
                                { attrs: { title: item.meta.title } },
                                [_vm._v(_vm._s(item.meta.title))]
                              )
                            ]),
                            item.state == 0
                              ? _c("img", {
                                  staticClass: "status",
                                  attrs: {
                                    src: require("../../../assets/img/icon_note_gray@3x.png")
                                  }
                                })
                              : _vm._e(),
                            item.state == 1
                              ? _c("img", {
                                  staticClass: "status",
                                  attrs: {
                                    src: require("../../../assets/img/icon_note_orange@3x.png")
                                  }
                                })
                              : _vm._e(),
                            item.state == 2
                              ? _c("img", {
                                  staticClass: "status",
                                  attrs: {
                                    src: require("../../../assets/img/icon_note_green@3x.png")
                                  }
                                })
                              : _vm._e(),
                            item.state == 9
                              ? _c("img", {
                                  staticClass: "status",
                                  attrs: {
                                    src: require("../../../assets/img/icon_note_red@3x.png")
                                  }
                                })
                              : _vm._e()
                          ])
                        ])
                      ]
                    )
                  : _c("CrfSubMenu", {
                      key: item.name,
                      staticClass: "submenu",
                      attrs: {
                        index: item.name,
                        "menu-info": item,
                        repeat: item.repeat,
                        "active-value": _vm.activeId
                      }
                    })
              ]
            }
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }