var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "my-card" }, [
    !_vm.noheader
      ? _c("div", { staticClass: "my-card_title" }, [_vm._t("header")], 2)
      : _vm._e(),
    _c("div", { staticClass: "my-card_body" }, [_vm._t("default")], 2),
    !_vm.nofooter
      ? _c("div", { staticClass: "my-card_footer" }, [_vm._t("footer")], 2)
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }