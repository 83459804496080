import Vue from 'vue';
export default {
  inserted: function inserted(el, binding) {
    if (!binding.value) {
      var cover = document.createElement('div');
      el.parentNode.replaceChild(cover, el);
      cover.appendChild(el); // el-button的处理

      if (hasClass(el, 'el-button')) {
        addClass(el, 'is-disabled'); // 使用innerHTML，会移除所有事件，即无权限时所有事件交互无效

        var res = Vue.compile("<el-tooltip class=\"item\" effect=\"dark\" content=\"\u6743\u9650\u4E0D\u8DB3\uFF0C\u8BF7\u8054\u7CFB\u7BA1\u7406\u5458\" placement=\"top-start\">\n           ".concat(cover.innerHTML, "\n        </el-tooltip>"));
        var Tooltip = Vue.extend({
          render: res.render
        });
        var tooltipIns = new Tooltip().$mount();
        empty(cover);
        cover.parentNode.replaceChild(tooltipIns.$el, cover);
      }
    }
  }
};

function hasClass(el, cls) {
  return (' ' + el.className + ' ').indexOf(' ' + cls + ' ') > -1;
}

function addClass(el, cls) {
  if (hasClass(el, cls)) {
    return;
  }

  var newClass = el.className.split(' ');
  newClass.push(cls);
  el.className = newClass.join(' ');
}

function empty(el) {
  while (el.firstChild) {
    el.removeChild(el.firstChild);
  }
}