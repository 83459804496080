//
//
//
//
import BasicLayout from './_basic.vue';
import request from '@/utils/request';
export default {
  components: {
    BasicLayout: BasicLayout
  },
  data: function data() {
    return {
      menuList: []
    };
  },
  mounted: function mounted() {
    var _this = this;

    request('/api/sys/login/getRouters').then(function (res) {
      res.data.forEach(function (element) {
        if (element.level === 1 && element.code != 'project.biology' && element.code != 'project.crfUpload') {
          _this.menuList.push(element);
        }
      });
    });
  }
};