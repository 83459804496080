import { devServerPath } from '@/settings';
import Enum from '@/utils/Enum';
export var VIDEOCLIENT = 'http://127.0.0.1:50001/'; // let wsPath = ''

var staticPath = '/';
var prefix = '';

switch (process.env.NODE_ENV) {
  case 'development':
    // wsPath = devServerPath + 'eventhub'
    staticPath = devServerPath;
    prefix = 'server/';
    break;

  case 'production':
    prefix = '';
}

export var BasicUrl = 'http://' + window.location.host + '/' + prefix;
export var PREFIX = prefix;
export var STATICPATH = staticPath; // export const WSPATH = wsPath

export var TokenKey = '$$token';
export var UserKey = '$$user';
export var RefreshToken = '$$refresh';
export var SEX_SELETE = [{
  text: '男',
  value: 1
}, {
  text: '女',
  value: 2
}]; // 字典枚举值

export var DICT_ENUM = new Enum().add('用户组等级', 14).add('数据权限', 74).add('组织类型', 17).add('管理等级', 42).add('地图类型', 18).add('区域结构类型', 19).add('区域业务类型', 34).add('监房类型', 77).add('终端类型', 28).add('触摸屏类型', 32).add('标签类型', 46).add('标签用途', 20).add('系统参数类型', 73).add('工具类型', 29).add('停用原因', 79).add('启用原因', 81).add('处理类型', 44).add('时段类型', 41).add('流动事由', 30).add('职位', 72).add('巡查线路类型', 82).add('巡查组类型', 83).add('巡查考核', 84).add('巡查状态', 85).add('外来事由', 87).add('追踪信息类型', 88).add('床垫类型', 89);
export var AREA_BUS_ENUM = new Enum().add('登记区域', 3428).add('巡查点', 3417).add('监房', 3401);
export var MAP_TYPE_ENUM = new Enum().add('总图', 1801).add('楼栋', 1802).add('楼层', 1803); // .add('真实节点', 1801)
// .add('虚拟节点', 1802)

export var DEVICE_TYPE_ENUM = new Enum().add('摄像机', 2810).add('视频服务器', 2811).add('手持机终端', 2851).add('报警指示器', 2814);
export var EVEVTCODE_ENUM = new Enum().add('工具发放', 1034).add('工具清点', 1032) // 计划清点工具清点结束
.add('临时工具清点', 1037).add('人员流动', 1065).add('人员清点结束', 1064).add('人员清点', 1063);
export var EVENTTYPE_ENUM = new Enum().add('报警', 0).add('事件', 1);
export var WEEKDAY_MAP = {
  0: '星期日',
  1: '星期一',
  2: '星期二',
  3: '星期三',
  4: '星期四',
  5: '星期五',
  6: '星期六'
};
export var ORG_TYEE_ENUM = new Enum().add('监所', 1701).add('监区', 1702).add('机关', 1703).add('其他', 1704);
export var PLAN_TYPE_ENUM = new Enum().add('工具清点计划', 2102).add('人员清点计划', 2101).add('巡查计划', 2103);
export var PERIOD_ENUM = new Enum().add('工具时段', 4102).add('人员时段', 4101);
export var MOVETYPE_ENUM = new Enum().add('出工', 2601).add('收工', 2602);
export var AREA_TYPE_ENUM = new Enum().add('功能区域', 1904).add('定位区域', 1901);
export var TAG_TYPE_ENUM = new Enum().add('胸牌', 4601).add('腕带', 4602).add('工具卡', 4603).add('工具盒', 4604).add('IC卡', 4605).add('工具检测盒', 4606).add('心率腕带', 4607);
export var SYS_MODE_ENUM = new Enum().add('看守所模式', 4001).add('监狱模式', 4002).add('戒毒所模式', 4003); // 无关联的id格式

export var EMPTY = '00000000-0000-0000-0000-000000000000';
export var DEGREE = {
  0: '#0fff0199',
  1: '#00e1ff99',
  2: '#cc771399',
  3: '#ff184499'
};
export var STATUSCOL = {
  3801: 'green',
  3802: '#999',
  3803: 'red',
  2501: 'green',
  2502: 'red'
};
export var OUTSIDER_TYPE_ENUM = new Enum().add('外来人员', 1).add('外来车辆', 2);
export var STAGEMAP = {
  0: '清醒',
  1: '浅睡眠',
  2: '中睡眠',
  3: '深睡眠'
};