/* eslint-disable */

/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  'search': {
    width: 32,
    height: 32,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M973.815 973.815a91.782 91.782 0 01-129.81 0L682.03 811.84a425.485 425.485 0 01-230.36 68.165A428.335 428.335 0 11880.005 451.67a425.485 425.485 0 01-68.165 230.36l162.03 161.975a91.837 91.837 0 01-.055 129.81zM451.67 145.694a305.977 305.977 0 100 611.953 305.977 305.977 0 000-611.953z"/>'
  }
});