function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { parseValidateRules } from './utils';
import getNet from '@/utils/reloadSide';
export default {
  name: 'el-crf',
  props: {
    option: {
      type: Object,
      required: true
    },
    formData: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      }
    },
    crfHidden: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    crfRules: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    echoUnites: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    var notFormComponentList = ['section', 'title'];
    return {
      validateRules: [],
      visible: false,
      notFormComponentList: notFormComponentList,
      isNotFormComponent: notFormComponentList.includes(this.option.type),
      indicantValue: '',
      iconId: '',
      needHideEl: [],
      needHideElString: '',
      haveChildren: false
    };
  },
  inject: ['getConvertedFormData', 'getIndicant', 'getSubject', 'getTemplate', 'getSectionLayout'],
  computed: {
    // convertedFormData() {
    //   return this.getConvertedFormData()
    // },
    template: function template() {
      return this.getTemplate();
    },
    indicant: function indicant() {
      return this.getIndicant();
    },
    subject: function subject() {
      return this.getSubject();
    },
    sectionLayout: function sectionLayout() {
      return this.getSectionLayout();
    }
  },
  watch: {
    option: {
      immediate: true,
      handler: function handler(v) {
        this.validateRules = parseValidateRules(v.type, v.option);
      }
    },
    formData: {
      deep: true,
      immediate: true,
      handler: function handler(v) {
        var _this = this;

        // 计算指标 质控 箭头
        if (this.option.option && this.option.option.refValueFemaleMax) {
          var val = parseFloat(v[this.option.name]);
          var _this$option$option = this.option.option,
              refValueMaleMin = _this$option$option.refValueMaleMin,
              refValueMaleMax = _this$option$option.refValueMaleMax,
              refValueFemaleMin = _this$option$option.refValueFemaleMin,
              refValueFemaleMax = _this$option$option.refValueFemaleMax;
          var min, max;

          if (this.subject.gender == 1) {
            min = refValueFemaleMin;
            max = refValueFemaleMax;
          } else {
            min = refValueMaleMin;
            max = refValueMaleMax;
          }

          if (val < min) {
            this.indicantValue = 'low';
          } else if (val > max) {
            this.indicantValue = 'high';
          } else {
            this.indicantValue = '';
          }
        } else {
          this.indicantValue = this.indicant[this.option.name] || '';
        }

        var visible = false;

        if (this.option.option) {
          // 单选子级显示和隐藏
          if (this.option.option.trig) {
            var _this$option$option2 = this.option.option,
                trig = _this$option$option2.trig,
                tindex = _this$option$option2.tindex; // visible = this.getTrigResult(trig, +tindex)

            if (this.getTrigResult(trig, +tindex)) {
              // 子级被展开后，检查hidden是否为true， true就隐藏
              if (this.crfHidden[this.option.name]) {
                visible = false;
              } else {
                visible = true;
              }
            } else {
              visible = false;
            }
          } // 多选子级显示和隐藏


          if (this.option.option.trigex) {
            var triges = this.option.option.trigex.split(',');
            var trigesMap = {};
            triges.map(function (item) {
              return item.split('-');
            }).forEach(function (_ref) {
              var _ref2 = _slicedToArray(_ref, 2),
                  trig = _ref2[0],
                  tindex = _ref2[1];

              if (!trigesMap[trig]) {
                trigesMap[trig] = [];
              }

              trigesMap[trig].push(+tindex);
            });
            var result = Object.keys(trigesMap).map(function (trig) {
              return _this.getTrigResult(trig, trigesMap[trig]);
            }); // visible = result.includes(true)

            if (result.includes(true)) {
              // 子级被展开后，检查hidden是否为true， true就隐藏
              if (this.crfHidden[this.option.name]) {
                visible = false;
              } else {
                visible = true;
              }
            } else {
              visible = false;
            }
          } // hidden


          if (!this.option.option.trig && !this.option.option.trigex) {
            visible = true;

            if (this.option.option.hasOwnProperty('hidden')) {
              visible = !this.crfHidden[this.option.name];
            }
          }
        } else {
          // section 默认为true
          visible = true;
        }

        this.visible = visible;
      }
    },
    crfHidden: {
      deep: true,
      immediate: true,
      handler: function handler(v) {
        if (this.option.option) {
          if (!this.option.option.trig && !this.option.option.trigex) {
            this.visible = true;

            if (this.option.option.hasOwnProperty('hidden')) {
              this.visible = !this.crfHidden[this.option.name];
            }
          }
        }
      }
    }
  },
  methods: {
    getTrigResult: function getTrigResult(trig, tindex) {
      var tindexArr = tindex;
      var v = this.formData;
      var trigOption;
      var visible = false;

      var findTrig = function findTrig(item) {
        if (item.name === trig) {
          trigOption = item;
        } else if (item.children) {
          item.children.forEach(findTrig);
        }
      };

      if (!Array.isArray(tindexArr)) tindexArr = [tindex];
      this.template.forEach(findTrig); // 每一条item trigOption

      if (trigOption && v[trigOption.name]) {
        var valueArr = trigOption.option.optionsValue.split(',');
        valueArr.forEach(function (val, i) {
          if (Array.isArray(v[trigOption.name])) {
            if (v[trigOption.name].includes(val) && tindexArr.includes(i)) {
              visible = true;
            }
          } else {
            if (v[trigOption.name] === val && tindexArr.includes(i)) {
              visible = true;
            }
          }
        });
      } else if (!trigOption) {
        visible = true;
      }

      getNet.$emit('handleVisible', this.option.name, visible);
      return visible;
    },
    getCols: function getCols(item) {
      // 如果类型是section 返回24 占一整行
      if (item.type === 'section') return 24;
      var cols = 1;

      if (item.option && item.option.cols) {
        cols = item.option.cols / this.sectionLayout.crfCols;
      } // if (item.option && item.option.cols) cols = item.option.cols == 1 ? 2 : 1


      if (cols >= 1) {
        return 24;
      } else {
        return 24 * cols;
      }
    },
    overIcon: function overIcon(e) {
      this.iconId = e;
    },
    offIcon: function offIcon() {
      this.iconId = '';
    },
    layoutFuc: function layoutFuc(sectionLayout, itemLayout) {
      if (itemLayout) {
        if (itemLayout === 'horizontal') {
          return true;
        } else {
          return false;
        }
      } else {
        if (sectionLayout === 'horizontal') {
          return true;
        } else {
          return false;
        }
      }
    },
    storeInputUnits: function storeInputUnits(v, itemName) {
      getNet.$emit('submitUnits', itemName, v);
    }
  }
};