var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          class: {
            "normal-form": _vm.collapseWidth === "282",
            "fold-form": _vm.collapseWidth === "50"
          },
          attrs: { model: _vm.formData }
        },
        _vm._l(_vm.template, function($item) {
          return _c("el-crf", {
            key: $item.id,
            style:
              "margin-left:" +
              _vm.sectionOptions.marginVal +
              "%; margin-right:" +
              _vm.sectionOptions.marginVal +
              "%",
            attrs: {
              formData: _vm.formData,
              crfHidden: _vm.crfHidden,
              sectionOptions: _vm.sectionOptions,
              option: $item,
              crfRules: _vm.rules,
              echoUnites: _vm.echoUnites
            }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }