import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

var modulesFiles = require.context('./modules', true, /\.js$/);

var modules = modulesFiles.keys().reduce(function (modules, modulePath) {
  var moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  var value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});
export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: modules
});