export default {
  computed: {
    imgEvent: function imgEvent() {
      return {
        error: this.imgError
      };
    }
  },
  methods: {
    imgError: function imgError(e) {
      e.target.src = require('@/assets/img/noimg.png');
    },
    imgSrc: function imgSrc() {
      var key = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

      if (!key) {
        return require('@/assets/img/noimg.png');
      } else if (~key.indexOf('/') || ~key.indexOf('\\')) {
        return key;
      } else {
        return "/api/v1/fileupload/downfile?fileKey=".concat(key);
      }
    }
  }
};