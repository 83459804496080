function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState, mapMutations } from 'vuex';
import { formatInput } from './utils.js';
export default {
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: ''
    },
    option: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      }
    },
    title: {
      type: String,
      default: ''
    },
    indicantValue: {
      type: String,
      default: ''
    },
    units: {
      type: String,
      default: ''
    },
    showStartDoubtIcon: {
      type: Boolean,
      default: false
    },
    showReplyDoubtIcon: {
      type: Boolean,
      default: false
    },
    groupInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState('study', ['typeOperate'])), mapState('crf', ['detail'])), {}, {
    showReplyIcon: function showReplyIcon() {
      var _this = this;

      var groupType = null;

      if (this.groupInfo.groupName) {
        if (this.detail.crfDoubts.length != 0) {
          groupType = this.detail.crfDoubts.some(function (item) {
            return item.itemName === _this.groupInfo.name && item.groupIndex === _this.groupInfo.valueIndex;
          });
        }
      } else {
        groupType = true;
      }

      return this.showReplyDoubtIcon && groupType;
    }
  }),
  data: function data() {
    return {
      innerValue: this.value,
      optionReadonly: false,
      unitsArr: [],
      chooseUnits: ''
    };
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function handler(v) {
        this.innerValue = v;
      }
    },
    option: {
      immediate: true,
      deep: true,
      handler: function handler(option) {
        if (option && option.readOnly && option.readOnly === 1) {
          this.optionReadonly = true;
        } else {
          this.optionReadonly = false;
        }

        if (option.units) {
          this.unitsArr = option.units.split(',');

          if (this.unitsArr.length > 1) {
            this.chooseUnits = this.unitsArr[0];
          }
        } // this.changeSaveItemSuc({ type: 'add', ...this.groupInfo })


        var params = _objectSpread(_objectSpread(_objectSpread({
          type: 'add'
        }, this.groupInfo), option.validationEx && {
          validationEx: option.validationEx
        }), option.validationErrorMsg && {
          validationErrorMsg: option.validationErrorMsg
        });

        this.changeSaveItemSuc(params);
      }
    },
    chooseUnits: {
      immediate: true,
      deep: true,
      handler: function handler(v) {
        this.$emit('inputUnits', v);
      }
    },
    units: {
      immediate: true,
      deep: true,
      handler: function handler(v) {
        if (v) {
          this.chooseUnits = v;
        }
      }
    }
  },
  methods: _objectSpread(_objectSpread({}, mapMutations('crf', ['changeSaveItemSuc'])), {}, {
    handleInput: function handleInput(v) {
      this.innerValue = v;
    },
    onChangeHandler: function onChangeHandler(event) {
      var value = event.target.value;
      this.innerValue = this.formatInputValue(value);

      if ('format' in this.option) {
        var tempStr = this.option.format;
        var splitIndex = tempStr.indexOf('[');
        var type = tempStr.slice(0, splitIndex);
        var rule = tempStr.slice(splitIndex, tempStr.length);
        var resV = formatInput(type, rule, this.innerValue);
        this.innerValue = resV;
        this.$emit('input', resV);
      } else {
        this.$emit('input', this.innerValue);
      }
    },
    formatInputValue: function formatInputValue(value) {
      // 使用正则表达式限制输入为数字和小数点
      var formatV = value.replace(/[^0-9.]/g, ''); // 确保只有一个小数点

      var parts = formatV.split('.');

      if (parts.length > 2) {
        formatV = parts[0] + '.' + parts.slice(1).join('');
      }

      return formatV;
    },
    showDoubtDialog: function showDoubtDialog() {
      // 打开质疑弹窗
      this.$emit('OpenDoubtDialog');
    }
  }),
  beforeDestroy: function beforeDestroy() {
    this.$emit('input', undefined);
    this.changeSaveItemSuc(_objectSpread({
      type: 'remove'
    }, this.groupInfo));
  }
};