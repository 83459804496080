var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "image-recognition" }, [
    _c(
      "div",
      { staticClass: "image-recognition_left" },
      [
        _vm.notUploaded
          ? _c(
              "el-upload",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading"
                  }
                ],
                attrs: {
                  action: "/",
                  "show-file-list": false,
                  drag: true,
                  accept: "image/*",
                  "http-request": _vm.handleUploadFuc,
                  "list-type": "picture-card"
                }
              },
              [
                _c("div", { staticClass: "slot-default" }, [
                  _c("i", {
                    staticClass: "el-icon-upload2",
                    attrs: { slot: "default" },
                    slot: "default"
                  }),
                  _c("div", { staticClass: "el-upload__tip" }, [
                    _vm._v("上传脊椎X光图像，或将其推拽至此处")
                  ])
                ])
              ]
            )
          : _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading"
                  }
                ],
                staticClass: "canvas-cover"
              },
              [
                _c(
                  "div",
                  {
                    ref: "container",
                    staticClass: "canvas-container",
                    style: _vm.containerStyle,
                    on: {
                      wheel: _vm.handleWheel,
                      mousedown: _vm.handleMouseDown
                    }
                  },
                  [
                    _c("canvas", {
                      ref: "canvasRef",
                      staticStyle: { "background-color": "#ccc" },
                      attrs: { width: "1084", height: "2437" }
                    })
                  ]
                )
              ]
            )
      ],
      1
    ),
    _c("div", { staticClass: "image-recognition_right" }, [
      _c(
        "div",
        { staticClass: "config-div" },
        [
          _c(
            "el-upload",
            {
              attrs: {
                action: "/",
                "http-request": _vm.handleUploadFuc,
                "show-file-list": false
              }
            },
            [
              _c("el-button", { attrs: { size: "small", type: "primary" } }, [
                _vm._v("点击上传")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "config-div label-config" },
        [
          _c(
            "div",
            { staticClass: "detection" },
            [
              _c("el-switch", {
                attrs: { "active-value": true, "inactive-value": false },
                on: {
                  change: function($event) {
                    return _vm.drawImage()
                  }
                },
                model: {
                  value: _vm.drawSettings.showDetections,
                  callback: function($$v) {
                    _vm.$set(_vm.drawSettings, "showDetections", $$v)
                  },
                  expression: "drawSettings.showDetections"
                }
              }),
              _c("span", { staticStyle: { "margin-left": "4px" } }, [
                _vm._v("显示检测")
              ])
            ],
            1
          ),
          _c(
            "el-checkbox",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.drawSettings.showDetections,
                  expression: "drawSettings.showDetections"
                }
              ],
              on: {
                change: function($event) {
                  return _vm.drawImage()
                }
              },
              model: {
                value: _vm.drawSettings.showDetectionLabels,
                callback: function($$v) {
                  _vm.$set(_vm.drawSettings, "showDetectionLabels", $$v)
                },
                expression: "drawSettings.showDetectionLabels"
              }
            },
            [_vm._v("显示检测标签")]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.drawSettings.showDetections,
                  expression: "drawSettings.showDetections"
                }
              ],
              staticClass: "scale"
            },
            [
              _c("span", { staticClass: "demonstration" }, [_vm._v("大小")]),
              _c("el-slider", {
                attrs: { min: 1, max: 4 },
                on: {
                  change: function($event) {
                    return _vm.drawImage()
                  }
                },
                model: {
                  value: _vm.drawSettings.detectionsScale,
                  callback: function($$v) {
                    _vm.$set(_vm.drawSettings, "detectionsScale", $$v)
                  },
                  expression: "drawSettings.detectionsScale"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "config-div points-config" },
        [
          _c("el-switch", {
            attrs: { "active-value": true, "inactive-value": false },
            on: {
              change: function($event) {
                return _vm.drawImage()
              }
            },
            model: {
              value: _vm.drawSettings.showLandmarks,
              callback: function($$v) {
                _vm.$set(_vm.drawSettings, "showLandmarks", $$v)
              },
              expression: "drawSettings.showLandmarks"
            }
          }),
          _c("span", { staticStyle: { "margin-left": "4px" } }, [
            _vm._v("显示关键点")
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "config-div cobb-config" },
        [
          _c("el-switch", {
            attrs: { "active-value": true, "inactive-value": false },
            on: {
              change: function($event) {
                return _vm.drawImage()
              }
            },
            model: {
              value: _vm.drawSettings.showCobbAngle,
              callback: function($$v) {
                _vm.$set(_vm.drawSettings, "showCobbAngle", $$v)
              },
              expression: "drawSettings.showCobbAngle"
            }
          }),
          _c("span", { staticStyle: { "margin-left": "4px" } }, [
            _vm._v("显示Cobb角度")
          ])
        ],
        1
      ),
      _c("div", { staticClass: "result" }, [
        _c(
          "div",
          { staticStyle: { "font-weight": "bold", "margin-bottom": "10px" } },
          [_vm._v("结果")]
        ),
        _c("p", { staticStyle: { "margin-bottom": "6px" } }, [
          _c("span", [_vm._v("曲线类型:")]),
          _vm._v(" "),
          _vm.scolioVisAPIResponse.curve_type
            ? _c("span", { staticClass: "resValue" }, [
                _vm._v(_vm._s(_vm.scolioVisAPIResponse.curve_type))
              ])
            : _vm._e()
        ]),
        _c("p", [
          _c("span", [_vm._v("近胸椎(PT):")]),
          _vm._v(" "),
          _vm.scolioVisAPIResponse.angles
            ? _c("span", { staticClass: "resValue" }, [
                _vm._v(
                  _vm._s(_vm.scolioVisAPIResponse.angles.pt.angle.toFixed(2)) +
                    "°"
                )
              ])
            : _vm._e()
        ]),
        _c("p", [
          _c("span", [_vm._v("主胸椎(MT):")]),
          _vm._v(" "),
          _vm.scolioVisAPIResponse.angles
            ? _c("span", { staticClass: "resValue" }, [
                _vm._v(
                  _vm._s(_vm.scolioVisAPIResponse.angles.mt.angle.toFixed(2)) +
                    "°"
                )
              ])
            : _vm._e()
        ]),
        _c("p", [
          _c("span", [_vm._v("胸腰椎/腰椎 (TL/L):")]),
          _vm._v(" "),
          _vm.scolioVisAPIResponse.angles
            ? _c("span", { staticClass: "resValue" }, [
                _vm._v(
                  _vm._s(_vm.scolioVisAPIResponse.angles.tl.angle.toFixed(2)) +
                    "°"
                )
              ])
            : _vm._e()
        ]),
        _c("div", { staticStyle: { "margin-top": "6px" } }, [
          _c("span", [_vm._v("最大弯度位于")]),
          _vm.scolioVisAPIResponse.angles
            ? _c("div", [
                _c("span", { staticClass: "resValue" }, [
                  _vm._v(
                    _vm._s(
                      _vm
                        .getMaxCobbAngle(_vm.scolioVisAPIResponse.angles)
                        .max.toUpperCase()
                    )
                  )
                ]),
                _c("span", { staticClass: "resValue" }, [
                  _vm._v(
                    _vm._s(
                      _vm
                        .getMaxCobbAngle(_vm.scolioVisAPIResponse.angles)
                        .value.toFixed(2)
                    ) + "°"
                  )
                ]),
                _c("span", [_vm._v("取自(")]),
                _c("span", { staticClass: "resValue" }, [
                  _vm._v(
                    _vm._s(
                      _vm.scolioVisAPIResponse.angles[
                        _vm.getMaxCobbAngle(_vm.scolioVisAPIResponse.angles).max
                      ].idxs[0] + 1
                    )
                  )
                ]),
                _c("span", [_vm._v(")的上终板和(")]),
                _c("span", { staticClass: "resValue" }, [
                  _vm._v(
                    _vm._s(
                      _vm.scolioVisAPIResponse.angles[
                        _vm.getMaxCobbAngle(_vm.scolioVisAPIResponse.angles).max
                      ].idxs[1] + 1
                    )
                  )
                ]),
                _c("span", [_vm._v(")的下终板.")])
              ])
            : _vm._e()
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }