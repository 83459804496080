var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "div",
        {
          staticClass: "crf-group",
          class: [{ "form-table": _vm.option.showStyle === "form" }]
        },
        [
          _c(
            "div",
            { staticClass: "crf-group_content" },
            [
              _c("el-" + _vm.option.showStyle, {
                tag: "component",
                attrs: {
                  value: _vm.value,
                  childList: _vm.childList,
                  crfRules: _vm.crfRules,
                  option: _vm.option
                },
                on: { input: _vm.onInputHandler }
              })
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }