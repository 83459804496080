var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.option.showStyle === "pop"
        ? _c(
            "el-button",
            { staticClass: "open-upload", on: { click: _vm.openNewDialog } },
            [_vm._v("上传视频")]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-info",
          attrs: {
            title: "上传视频",
            width: "35%",
            visible: _vm.showUploadDialog,
            "before-close": _vm.dialogClose,
            "lock-scroll": false
          }
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-form-item",
                { attrs: { label: "选择视频" } },
                [
                  !_vm.dialogVideoUrl
                    ? _c(
                        "el-upload",
                        {
                          attrs: {
                            id: "dialog-upload",
                            action: "/",
                            "list-type": "picture-card",
                            "show-file-list": false,
                            "http-request": _vm.dialogHandleUploadSuccess,
                            accept: "video/*"
                          }
                        },
                        [
                          !_vm.dialogVideoUrl
                            ? _c("i", {
                                staticClass: "el-icon-plus",
                                attrs: { slot: "default" },
                                slot: "default"
                              })
                            : _vm._e()
                        ]
                      )
                    : _c("video", {
                        attrs: { src: _vm.dialogVideoUrl + "_thumbnail.jpg" },
                        on: {
                          click: function() {
                            _vm.visible = true
                            _vm.previewUrl = _vm.dialogVideoUrl
                          }
                        }
                      })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "时间" } },
                [
                  _c("el-date-picker", {
                    model: {
                      value: _vm.videoTime,
                      callback: function($$v) {
                        _vm.videoTime = $$v
                      },
                      expression: "videoTime"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      "show-word-limit": true,
                      maxlength: 180
                    },
                    model: {
                      value: _vm.remarks,
                      callback: function($$v) {
                        _vm.remarks = $$v
                      },
                      expression: "remarks"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.showUploadDialog = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.dialogHandlerOk }
                },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.dialogData.length != 0,
              expression: "dialogData.length != 0"
            }
          ]
        },
        _vm._l(_vm.dialogData, function(item, idx) {
          return _c("span", { key: idx, staticClass: "dialog-img_card" }, [
            _c("video", {
              attrs: { src: item.dialogVideoUrl + "_thumbnail.jpg" },
              on: {
                click: function($event) {
                  return _vm.showDialog(item, idx)
                }
              }
            }),
            _c(
              "label",
              {
                staticClass: "right-top",
                on: {
                  click: function($event) {
                    return _vm.delDialogData(idx)
                  }
                }
              },
              [_c("i", { staticClass: "el-icon-delete-solid" })]
            )
          ])
        }),
        0
      ),
      !_vm.option.showStyle && !_vm.option.defaultValue
        ? _c(
            "el-upload",
            {
              attrs: {
                id: "upload-video",
                action: "/",
                "list-type": "picture-card",
                "before-upload": _vm.limitVideoSizeFuc,
                "http-request": _vm.onUploadHandler,
                accept: "video/*",
                multiple: "",
                "file-list": _vm.list,
                "lock-scroll": false
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "file",
                    fn: function(ref) {
                      var file = ref.file
                      return _c(
                        "div",
                        {},
                        [
                          file.status === "ready"
                            ? _c("el-progress", {
                                staticClass: "video-progress",
                                attrs: {
                                  type: "circle",
                                  percentage: _vm.progress
                                }
                              })
                            : _vm._e(),
                          file.status === "success"
                            ? _c("video", {
                                staticClass: "video-thumbnail",
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: { src: file.realUrl }
                              })
                            : _vm._e(),
                          _c(
                            "span",
                            { staticClass: "el-upload-list__item-actions" },
                            [
                              _c(
                                "span",
                                { staticClass: "el-upload-list__item-icon" },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-search",
                                    on: {
                                      click: function($event) {
                                        return _vm.onPreiviewHandler(file)
                                      }
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "span",
                                { staticClass: "el-upload-list__item-icon" },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-delete",
                                    on: {
                                      click: function($event) {
                                        return _vm.onRemoveHandler(file)
                                      }
                                    }
                                  })
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    }
                  }
                ],
                null,
                false,
                407044197
              )
            },
            [
              _c("i", {
                staticClass: "el-icon-plus",
                attrs: { slot: "default" },
                slot: "default"
              })
            ]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.visible,
            title: "视频预览",
            "show-close": "",
            "lock-scroll": false
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c("video", {
            attrs: {
              src: _vm.previewUrl,
              alt: "",
              controls: "controls",
              "append-to-body": true
            }
          })
        ]
      ),
      _vm.option.showStyle === "display"
        ? _c("video", {
            staticStyle: { "max-width": "100%" },
            attrs: { src: _vm.option.defaultValue, controls: "controls" }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }