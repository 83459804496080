import { isIE11 } from './platfrom';
var instancesMap = {};
var playing = false; // const sourceMP3 = [
//   'alert.mp3',
//   'info.wav'
// ]

export function preload() {
  // sourceMP3.forEach((key) => {
  //   const audio = new Audio()
  //   audio.src = require('@/assets/media/' + key)
  //   instancesMap[key] = audio
  // })
  if (!isIE11) {
    document.addEventListener('click', initial);
  }
}

function initial() {
  Object.values(instancesMap).forEach(function (ins) {
    console.log(ins.volume);
    ins.volume = 0;
    ins.play().then(function () {
      ins.volume = 1;
      ins.pause();
    });
  });
  document.removeEventListener('click', initial);
}

export function playAudio(key) {
  var ins = instancesMap[key];

  if (!ins) {
    return;
  }

  if (!playing) {
    ins.play().then(function () {
      playing = true;
    });
  }

  ins.onended = function () {
    playing = false;
  };
}