import '@babel/polyfill';
import fin from 'promise.prototype.finally';
import Vue from 'vue';
import 'normalize.css/normalize.css';
import '../public/fonts/iconfont.css';
import moment from 'moment';
import _ from 'lodash'; // eslint-disable-next-line no-unused-vars

import $ from 'jquery';
import '../theme/index.css';
import Element from 'element-ui';
import '../src/styles/element-variables.scss'; // import Contextmenu from 'vue-contextmenujs'
// import VueKonva from 'vue-konva'
// import VueDND from 'awe-dnd'

import Table from '@/components/Table';
import Plane from '@/components/Plane';
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
import SvgIcon from 'vue-svgicon'; // 引入所有svg组件

import './icons/components';
import './icons/iconfont/iconfont.css';
import './utils/polifill'; // 引入antd的描述组件

import Descriptions from 'ant-design-vue/lib/descriptions';
import 'ant-design-vue/lib/descriptions/style/css';
import Plugin from 'v-fit-columns';
import './permission'; // 权限控制

import perm from '@/plugin/perm'; // 按钮权限指令

import ImgMixin from '@/plugin/mixins/ImgMixin';
import App from './App';
import store from './store';
import router from './router';
import loadingPlugin from './plugin/v-loading-plugin'; // dom的resize事件监听

import '@/utils/resize'; // 自适应

import '@/utils/rem';
import 'moment/locale/zh-cn';
import FullCalendar from 'vue-fullcalendar';
import * as echarts from 'echarts';
import directiveAuth from './directive/auth';
import QRcode from './components/QRcode';
import tableMove from '@/utils/tableMove.js';
Vue.use(tableMove);
Vue.use(QRcode);
Vue.prototype.$echarts = echarts;
Vue.prototype.$moment = moment;
Vue.directive('auth', directiveAuth);
moment.locale('zh-cn', {
  weekdays: '星期日_星期一_星期二_星期三_星期四_星期五_星期六'.split('_')
});
Vue.use(FullCalendar);
fin.shim();
Vue.use(loadingPlugin, {
  namespace: '$loadingPlugin'
});
Vue.use(Element, {
  size: 'medium'
});
Vue.use(perm);
Vue.use(Descriptions);
Vue.use(Plugin); // Vue.use(Contextmenu)
// Vue.use(VueKonva)
// Vue.use(VueDND)
// Vue.use(scroll)

Vue.mixin(ImgMixin);
Vue.use(SvgIcon, {
  tagName: 'svg-icon',
  defaultWidth: '20px',
  defaultHeight: '20px'
});
Vue.component(CollapseTransition.name, CollapseTransition);
Vue.component(Table.name, Table);
Vue.component(Plane.name, Plane);

Vue.prototype.$formatDate = function (data) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'YYYY-MM-DD HH:mm:ss';
  if (!data || ~data.indexOf('1900')) return '';
  return moment(data).format(format);
}; // external 外网七牛上传  internal 内网上传


Vue.prototype.$network = 'external';
Vue.prototype._get = _.get;

Vue.prototype.$boardcast = function (eventName, data) {
  boardcast.call(this, eventName, data);
};

function boardcast(eventName, data) {
  this.$emit(eventName, data);
  this.$children.forEach(function (children) {
    children.$emit(eventName, data);

    if (children.$children.length > 0) {
      boardcast.call(children, eventName, data);
    }
  });
}

Vue.config.productionTip = false;
router.beforeResolve(function (to, from, next) {
  if (to.name && to.name.indexOf('project') >= 0) {
    store.dispatch('study/refreshStudyId', to.params.id);
  } //  else {
  //   store.dispatch('study/refreshStudyId', '')
  // }


  next();
});
new Vue({
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');