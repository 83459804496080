/* eslint-disable */

/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  'dashboard': {
    width: 32,
    height: 32,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M862.98 568.706H645.47a111.538 111.538 0 00-109.039 113.582v228.13A111.538 111.538 0 00645.471 1024H862.98a111.424 111.424 0 00108.47-113.582V682.515a111.424 111.424 0 00-108.47-113.582zm-507.712 0h-217.51A111.424 111.424 0 0029.287 682.288v228.13A111.424 111.424 0 00137.757 1024h217.51a111.538 111.538 0 00109.04-113.582V682.515a111.538 111.538 0 00-109.04-113.582zm0-531.167h-217.51A111.424 111.424 0 0029.287 151.12V378.74a111.424 111.424 0 00108.47 113.582h217.51a111.538 111.538 0 00109.04-113.582V151.348a111.538 111.538 0 00-109.04-113.866zm391.858 480.396a116.706 116.706 0 01-84.619-36.687l-127.212-133.63a129.086 129.086 0 010-177.188L662.507 36.8a116.024 116.024 0 01169.238 0l127.78 133.63a129.824 129.824 0 010 177.188l-127.78 133.63a115.797 115.797 0 01-84.619 36.687zm0-456.146a57.984 57.984 0 00-42.593 18.627l-127.78 133.63a64.799 64.799 0 000 90.013l127.78 133.63a58.608 58.608 0 0085.754 0l127.78-133.63a66.73 66.73 0 000-90.013l-127.78-133.63a59.233 59.233 0 00-43.16-18.627z"/>'
  }
});