function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
export default {
  props: {
    value: {
      type: String,
      required: false
    },
    option: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      }
    }
  },
  computed: _objectSpread({}, mapState('study', ['typeOperate'])),
  data: function data() {
    return {
      list: [],
      defaultValue: null,
      optionReadonly: false
    };
  },
  watch: {
    option: {
      handler: function handler(v) {
        if (v && v.readOnly && v.readOnly === 1) {
          this.optionReadonly = true;
        } else {
          this.optionReadonly = false;
        }

        if (v.defaultValue != '') {
          this.defaultValue = v.defaultValue;
        }

        var _ref = v || {},
            _ref$optionsValue = _ref.optionsValue,
            optionsValue = _ref$optionsValue === void 0 ? '' : _ref$optionsValue,
            _ref$optionsText = _ref.optionsText,
            optionsText = _ref$optionsText === void 0 ? '' : _ref$optionsText;

        var labelArr = optionsText.split(',');
        this.list = optionsValue.split(',').map(function (item, i) {
          return {
            label: labelArr[i],
            value: item
          };
        });
      },
      immediate: true
    }
  },
  methods: {
    onChangeHandler: function onChangeHandler(v) {
      this.$emit('input', v);
    },
    onInputChange: function onInputChange(event) {
      var _this = this;

      this.list.forEach(function (item) {
        if (event.target.value === item.value) {
          _this.$emit('input', event.target.value);
        }
      });
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.$emit('input', undefined);
  }
};