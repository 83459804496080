//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'SubMenu',
  props: {
    menuInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  mounted: function mounted() {}
};