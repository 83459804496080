//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    option: {
      type: Object,
      required: true
    }
  },
  inject: ['getSectionLayout'],
  computed: {
    sectionLayout: function sectionLayout() {
      return this.getSectionLayout();
    }
  }
};