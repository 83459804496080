if (!String.prototype.padStart) {
  // eslint-disable-next-line no-extend-native
  String.prototype.padStart = function padStart(targetLength, padString) {
    targetLength = targetLength >> 0; // floor if number or convert non-number to 0;

    padString = String(typeof padString !== 'undefined' ? padString : ' ');

    if (this.length > targetLength) {
      return String(this);
    } else {
      targetLength = targetLength - this.length;

      if (targetLength > padString.length) {
        padString += padString.repeat(targetLength / padString.length); // append to original to ensure we are longer than needed
      }

      return padString.slice(0, targetLength) + String(this);
    }
  };
}

if (!Array.flat) {
  // eslint-disable-next-line no-extend-native
  Array.prototype.flat = function () {
    var arr = [];
    this.forEach(function (item, idx) {
      if (Array.isArray(item)) {
        arr = arr.concat(item.flat());
      } else {
        arr.push(item);
      }
    });
    return arr;
  };
}

var throttle = function throttle(type, name, obj) {
  obj = obj || window;
  var running = false;

  var func = function func() {
    if (running) {
      return;
    }

    running = true;
    requestAnimationFrame(function () {
      obj.dispatchEvent(new CustomEvent(name));
      running = false;
    });
  };

  obj.addEventListener(type, func);
};

throttle('resize', 'optimizedResize');