var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dialog",
    {
      attrs: {
        visible: _vm.visible,
        option: _vm.option,
        width: "420px",
        "show-close": !_vm.param.setPwdMust,
        "close-on-press-escape": !_vm.param.setPwdMust,
        handleBeforeClose: _vm.handleClose
      },
      on: { handleClose: _vm.handleClose, handleOk: _vm.handleOk }
    },
    [
      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
        _vm._v("修改密码")
      ]),
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.ruleForm,
                "status-icon": "",
                rules: _vm.rules
              }
            },
            [
              _vm.param.type != "reset"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "原始密码", prop: "oldPass" } },
                    [
                      _c("el-input", {
                        attrs: { autocomplete: "off", "show-password": "" },
                        model: {
                          value: _vm.ruleForm.oldPass,
                          callback: function($$v) {
                            _vm.$set(_vm.ruleForm, "oldPass", $$v)
                          },
                          expression: "ruleForm.oldPass"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.param.type === "reset"
                ? _c(
                    "el-form-item",
                    { staticClass: "user-name", attrs: { label: "用户名" } },
                    [
                      _c("el-input", {
                        attrs: { autocomplete: "off", readonly: "" },
                        model: {
                          value: _vm.param.name,
                          callback: function($$v) {
                            _vm.$set(_vm.param, "name", $$v)
                          },
                          expression: "param.name"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  staticClass: "short-input",
                  attrs: { label: "新密码", prop: "pass" }
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", "show-password": "" },
                    model: {
                      value: _vm.ruleForm.pass,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "pass", $$v)
                      },
                      expression: "ruleForm.pass"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "确认密码", prop: "checkPass" } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", "show-password": "" },
                    model: {
                      value: _vm.ruleForm.checkPass,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "checkPass", $$v)
                      },
                      expression: "ruleForm.checkPass"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v("取 消")
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.submitOk("ruleForm")
                }
              }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }