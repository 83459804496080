// import Vue from 'vue'
export default {
  // el: 元素， binding: 一个对象
  inserted: function inserted(el, binding, vnode) {
    handleAuthDiretive(el, binding, vnode);
  },
  update: function update(el, binding, vnode) {
    handleAuthDiretive(el, binding, vnode);
  }
};

function handleAuthDiretive(el, binding, vnode) {
  // permissions 获取到的是后台的code集合
  var permissions = vnode.context.$store._modules.root.state.user.permissions;
  var btnAuth = [];

  if (localStorage.getItem('btnAuth')) {
    btnAuth = localStorage.getItem('btnAuth').split(',');
  } // binding.value: 指令的绑定值


  if (btnAuth && btnAuth.includes(binding.value) || permissions && permissions.includes(binding.value)) {
    el.style.display = 'inline-block';
  } else {
    el.style.display = 'none';
  }
}
/* function hasClass(el, cls) {
  return (' ' + el.className + ' ').indexOf(' ' + cls + ' ') > -1
}

function addClass(el, cls) {
  if (hasClass(el, cls)) {
    return
  }
  const newClass = el.className.split(' ')
  newClass.push(cls)
  el.className = newClass.join(' ')
}

function empty(el) {
  while (el.firstChild) {
    el.removeChild(el.firstChild)
  }
} */