//
//
//
//
//
//
//
//
//
//
//
//
//
//
import imgMapping from './constant';
import iconMapping from './iconConstant';
export default {
  props: {
    model: {
      type: Object,
      required: false,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      imgMapping: imgMapping,
      iconMapping: iconMapping,
      currentRoute: {}
    };
  },
  watch: {
    model: {
      immediate: true,
      handler: function handler(v) {
        this.currentRoute = this.$router.resolve({
          name: v.code
        }).route;
      }
    }
  }
};