var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-input", {
    attrs: {
      value: _vm.value,
      type: "textarea",
      placeholder: _vm.option.description,
      "show-word-limit": "",
      disabled: _vm.optionReadonly || _vm.typeOperate
    },
    on: { input: _vm.onChangeHandler }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }