import { render, staticRenderFns } from "./list.vue?vue&type=template&id=6879848a&scoped=true&"
import script from "./list.vue?vue&type=script&lang=js&"
export * from "./list.vue?vue&type=script&lang=js&"
import style0 from "./list.vue?vue&type=style&index=0&id=6879848a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6879848a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/gaoxiaoxu/workCodeSpace/医疗数据库/fozo-medical-research-cloud-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6879848a')) {
      api.createRecord('6879848a', component.options)
    } else {
      api.reload('6879848a', component.options)
    }
    module.hot.accept("./list.vue?vue&type=template&id=6879848a&scoped=true&", function () {
      api.rerender('6879848a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/dynamic-form/components/list.vue"
export default component.exports