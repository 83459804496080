var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.page
    ? _c(
        "div",
        { staticClass: "pagination-container" },
        [
          _c(
            "el-pagination",
            _vm._b(
              {
                attrs: {
                  background: _vm.background,
                  "current-page": _vm.page.page,
                  "page-size": _vm.page.pageSize,
                  layout: _vm.layout,
                  "page-sizes": _vm.pageSizes,
                  total: _vm.page.total
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange
                }
              },
              "el-pagination",
              _vm.$attrs,
              false
            )
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }