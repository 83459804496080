//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'componentItem',
  props: {
    childData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    groupTable: {
      type: Boolean,
      default: false
    },
    tableDerection: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      showChild: false
    };
  },
  watch: {
    childData: {
      immediate: true,
      deep: true,
      handler: function handler(v) {
        var _this = this;

        if (Array.isArray(v)) {
          v.forEach(function (data) {
            if (data.value) {
              _this.showChild = true;
            }

            if (_this.groupTable) {
              if (data.option.cols) {
                data.option.cols = '';
              }
            }
          });
        }
      }
    }
  },
  methods: {
    resetImg: function resetImg(imgItem) {
      if (imgItem.value && imgItem.value.length != 0) {
        if (imgItem.option.showStyle === 'pop') {
          var tempImgArr = [];
          imgItem.value.forEach(function (img) {
            tempImgArr.push(img.dialogImgUrl);
          });
          return tempImgArr;
        } else {
          return imgItem.value;
        }
      }

      return [];
    }
  }
};