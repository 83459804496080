var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sidebar-main-menu" }, [
    _c(
      "div",
      { staticClass: "wrapper" },
      [
        _c("Sidebar", { attrs: { "menu-list": _vm.menuList } }),
        _c(
          "div",
          {
            staticClass: "content-page",
            class: { collapsed: _vm.collapse },
            attrs: { id: "content-page" }
          },
          [_c("Navbar"), _c("AppMain")],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }