var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-time-picker", {
        staticClass: "crf-time",
        attrs: {
          value: _vm.value,
          placeholder: _vm.option.description,
          "value-format": "HH:mm:ss",
          disabled: _vm.optionReadonly || _vm.typeOperate
        },
        on: { input: _vm.onChangeHandler }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }