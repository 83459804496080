function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { Sidebar, Navbar, AppMain } from './components/index';
import SpeechSynthesisMixin from '@/plugin/mixins/SpeechSynthesisMixin';
import { mapActions, mapState } from 'vuex'; // import request from '@/utils/request'

export default {
  mixins: [SpeechSynthesisMixin],
  components: {
    Sidebar: Sidebar,
    Navbar: Navbar,
    AppMain: AppMain
  },
  provide: function provide() {
    return {
      registAlarm: this.registAlarmFn,
      unregistAlarm: this.unregistAlarmFn
    };
  },
  props: {
    menuList: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    }
  },
  computed: _objectSpread(_objectSpread({}, mapState('study', ['studyId'])), mapState('app', ['collapse', 'collapseWidth'])),
  data: function data() {
    return {
      notifyMap: {},
      dispatchArr: []
    };
  },
  watch: {
    menuList: {
      immediate: true,
      handler: function handler(v) {
        var btnPerms = [];

        var loop = function loop(item) {
          if (item.type === 'F' && item.code) {
            btnPerms.push(item.code);
          }

          if (item.children) {
            item.children.map(loop);
          }
        };

        if (v) {
          v.map(loop);
        }

        if (btnPerms.length != 0) {
          localStorage.setItem('btnAuth', btnPerms);
        } // v-auth 值存储在store里面


        this.$store.dispatch('user/setPermissions', btnPerms);
      }
    },
    collapseWidth: {
      deep: true,
      immediate: true,
      handler: function handler(v) {
        $('#content-page').css('margin-left', v + 'px');
      }
    }
  },
  created: function created() {
    this.getUserInfo(); // this.getRouter()
  },
  methods: _objectSpread({}, mapActions('user', ['getUserInfo', 'getRouter'])),
  mounted: function mounted() {
    var that = this;
    jQuery('#content-page').scroll(function () {
      if (jQuery('#content-page').scrollTop() >= 75) {
        if (that.collapseWidth === '282') {
          jQuery('.iq-top-navbar').addClass('fixed-header');
        } else if (that.collapseWidth === '50') {
          jQuery('.iq-top-navbar').addClass('fixed-header-fold');
        }
      } else {
        if (that.collapseWidth === '282') {
          jQuery('.iq-top-navbar').removeClass('fixed-header');
        } else if (that.collapseWidth === '50') {
          jQuery('.iq-top-navbar').removeClass('fixed-header-fold');
        }
      }
    });
  }
};