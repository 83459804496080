var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-submenu",
    {
      staticClass: "menu-item-wrap",
      attrs: { index: _vm.menuInfo.name, route: _vm.menuInfo }
    },
    [
      _c("template", { slot: "title" }, [
        _c("span", [_vm._v(_vm._s(_vm.menuInfo.meta.title))])
      ]),
      _vm._l(
        _vm.menuInfo.children.filter(function(i) {
          return i.meta && !i.meta.hidden
        }) || [],
        function(item) {
          return [
            !item.children || item.children.length === 0
              ? _c(
                  "el-menu-item",
                  { key: item.name, attrs: { index: item.name, route: item } },
                  [
                    _c("div", { staticClass: "whiteBg" }, [
                      _c("div", { staticClass: "pane" }, [
                        _c("span", [_vm._v(_vm._s(item.meta.title))])
                      ])
                    ])
                  ]
                )
              : _c("sub-menu", {
                  key: item.name,
                  staticClass: "submenu",
                  attrs: { index: item.name, "menu-info": item }
                })
          ]
        }
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }