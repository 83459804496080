/* eslint-disable */

/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon';
icon.register({
  'map_ceng': {
    width: 34.594,
    height: 32,
    viewBox: '0 0 1107 1024',
    data: '<defs/><path pid="0" d="M549.883 591.204a78.444 78.444 0 01-33.192-7.275L54.493 371.584a84.28 84.28 0 010-152.13L516.414 7.136a79.495 79.495 0 0166.384 0l462.198 212.346a84.28 84.28 0 010 152.13L583.074 583.957a80.214 80.214 0 01-33.191 7.247zm-.277 232.703a44.477 44.477 0 01-18.532-4.121L26.003 580.859A46.745 46.745 0 014.15 520.007a44.81 44.81 0 0140.937-26.775 43.205 43.205 0 0118.532 4.205l486.263 229.992 493.177-230.158a44.256 44.256 0 0159.192 22.902 46.164 46.164 0 01-22.128 60.852L568.414 819.87a45.03 45.03 0 01-18.808 4.038zm0 199.871a44.892 44.892 0 01-18.532-4.121L26.003 780.702A45.39 45.39 0 012.768 754.84a46.8 46.8 0 011.383-35.128 44.754 44.754 0 0140.66-26.747 42.403 42.403 0 0118.81 4.26L549.882 927.19l493.177-230.131a43.481 43.481 0 0118.532-4.066 44.726 44.726 0 0140.66 26.913 47.022 47.022 0 011.383 35.211 46.358 46.358 0 01-23.511 25.724l-511.71 238.76a43.896 43.896 0 01-18.808 4.177z"/>'
  }
});