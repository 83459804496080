function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex'; // import AFTableColumn from 'af-table-column'

export default {
  props: {
    childList: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    value: {
      type: String,
      required: false,
      default: function _default() {
        return '';
      }
    },
    option: {
      type: Object,
      required: false,
      default: function _default() {}
    }
  },
  // components: { AFTableColumn },
  computed: _objectSpread({}, mapState('study', ['typeOperate'])),
  watch: {
    childList: {
      immediate: true,
      handler: function handler(v) {
        this.columns = [];
        if (!v) return;
        this.columns = v.map(function (item) {
          return {
            label: item.title,
            prop: item.name,
            type: item.type,
            option: item.option,
            minWidth: 100,
            render: true
          };
        });
      }
    },
    value: {
      deep: true,
      immediate: true,
      handler: function handler(v) {
        if (v === JSON.stringify(this.list) || !v) return;
        this.list = JSON.parse(v);
      }
    },
    list: {
      immediate: true,
      deep: true,
      handler: function handler(v) {
        var str = JSON.stringify(v);
        if (str !== this.value) this.$emit('input', str);
      }
    },
    option: {
      immediate: true,
      deep: true,
      handler: function handler(option) {
        if (option && option.readOnly && option.readOnly === 1) {
          this.optionReadonly = true;
        } else {
          this.optionReadonly = false;
        }
      }
    }
  },
  data: function data() {
    return {
      list: [],
      columns: [],
      tdData: {},
      tableKey: 0,
      optionReadonly: false
    };
  },
  methods: {
    onAddHandler: function onAddHandler() {
      var dataParam = {};
      this.columns.forEach(function (el) {
        dataParam[el.prop] = '';
      });
      this.list.push(dataParam);
    },
    onRemoveHandler: function onRemoveHandler(idx) {
      this.list.splice(idx, 1);
    },
    valueChange: function valueChange(e, scope, col) {
      if (scope.$index < this.list.length) {
        this.$set(this.list[scope.$index], [col.prop], e);
      }
    },
    calculateMinWidth: function calculateMinWidth(col) {
      /*
      console.log(col)
      // 设置一个基础宽度，可以根据需要调整
      const baseWidth = 150
      // 根据列名的字数计算最小宽度
      return Math.max((col.label.length * 16 + 40), baseWidth)
      */
      if (col.type === 'date') {
        return 180;
      } else if (col.type === 'int' || col.type === 'real' || col.type === 'text') {
        var inputLen = 150 + (col.option.units ? col.option.units.length * 16 : 0);
        return inputLen;
      } else {
        var baseWidth = 150; // 根据列名的字数计算最小宽度

        return Math.max(col.label.length * 16 + 40, baseWidth);
      }
    }
  }
};